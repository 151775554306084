export const getActiveQuery = (location, key) => {
  return location.search.split(`${key}=`)[1]
    ? location.search.split(`${key}=`)[1].split('&')[0]
    : undefined;
};

export const generateSlNo = (index, page = 1, limit = 10) => {
  let currentPage = page;
  let rowCount = 0;
  if (page < 1) {
    currentPage = 1;
  }
  rowCount = (currentPage - 1) * limit;
  return rowCount + index + 1;
};

export const getDistance = distance => {
  const oneMeterToMiles = 0.000621371;
  return parseFloat(distance * oneMeterToMiles).toFixed(2);
};

export const getFileUrl = link => {
  const newLink = `${process.env.REACT_APP_BASE_URL}/${link}`;
  return newLink;
};

export const downloadFile = Link => {
  const link = document.createElement('a');
  link.href = Link;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
