import { useHistory, useLocation } from 'react-router-dom';
import { getActiveQuery } from '../utils';

// const PAGE = 'page';

const usePage = (pageKey = 'page') => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const page = getActiveQuery(location, pageKey);

  const handlePage = limit => {
    searchParams.set(pageKey, limit);
    const newQueryString = searchParams.toString();
    history.replace({
      search: newQueryString,
    });
  };

  return {
    handlePage,
    page: page || 1,
  };
};

export default usePage;
