import React, { useState } from 'react';
import useColumnSearch from '../../../hooks/useColumnSearch';
const FilterColumnInput = ({ placeholder, searchKey, columnKey }) => {
  const [value, setValue] = useState();
  const { handleInputChange, searchInput } = useColumnSearch(
    searchKey,
    'page',
    columnKey,
  );

  return (
    <input
      className="w-[150px] h-[30px] text-xs border px-2 rounded-sm font-medium"
      placeholder={`Search ${placeholder}`}
      onChange={e => {
        handleInputChange(e.target.value);
        setValue(e.target.value ? e.target.value : '');
      }}
      value={typeof value !== 'string' ? searchInput : value}
    />
  );
};

export default FilterColumnInput;
