import store from 'store/reducers';
export const getUrl = url => {
  let state = store.getState();
  let userData = state.auth.userData;
  if (!userData || Object.keys(userData).length === 0) {
    userData.role = localStorage.getItem('role');
    userData.officeId = localStorage.getItem('office-id');
  }
  if (userData.role === 'admin') return url;
  else if (userData.role === 'office-manager' && userData.officeId)
    return `offices/${userData.officeId}/${url}`;
  else return `offices/${userData.officeId}/${url}`;
};
