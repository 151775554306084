import React, { useEffect, useRef } from 'react';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';
import { useSelector } from 'react-redux';
function Toast() {
  const notificationSystem = useRef();
  const toastData = useSelector(state => state.toast.toastData);
  useEffect(() => {
    if (toastData && toastData.show) {
      let notification = notificationSystem.current;

      const { title, type, message } = toastData;
      notification.addNotification({
        title: title || '',
        message: message || 'Your message',
        level: type || 'info',
      });
    }
  }, [toastData]);
  return (
    <div>
      <NotificationSystem
        dismissible={false}
        ref={notificationSystem}
        style={NOTIFICATION_SYSTEM_STYLE}
      />
    </div>
  );
}

export default Toast;
