import React, { useMemo } from 'react';
import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import Table from '../../components/shared/table/Table';
import { DATE_FORMAT_FOURTH } from '../../utils/constants';
import { generateSlNo, getActiveQuery } from '../../utils';
import { useLocation } from 'react-router-dom';
import { useVisitedLocations } from '../../apis/queries/users.queries';

const RouteVisitedLocations = ({ routeId }) => {
  const columnHelper = createColumnHelper();
  const location = useLocation();
  // const [visitLocations, setVisitLocations] = useState([]);
  // const titleCase = str => {
  //   var splitStr = str.toLowerCase().split(' ');
  //   for (var i = 0; i < splitStr.length; i++) {
  //     // You do not need to check if i is larger than splitStr length, as your for does that for you
  //     // Assign it back to the array
  //     splitStr[i] =
  //       splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  //   }
  //   // Directly return the joined string
  //   return splitStr.join(' ');
  // };
  // useEffect(() => {
  //   if (visits.visits && visits.visits.length > 0) {
  //     const list = visits.visits.map((item, index) => ({
  //       sl_no: index + 1,
  //       note: item.note,
  //       type: titleCase(item.location[0].type),
  //       location: item.location[0].dbaName || item.location[0].description,
  //       actually_visited: item.actually_visited ? 'Yes' : 'No',
  //       doNotVisit: item.doNotVisit ? 'Yes' : 'No',
  //       arrivalTime: item.arrival
  //         ? moment(item.arrival).format('MM-DD-YYYY HH:mm')
  //         : 'NA',
  //       departureTime: item.departure
  //         ? moment(item.departure).format('MM-DD-YYYY HH:mm')
  //         : 'NA',
  //     }));

  //     // setVisitLocations(list);
  //   }
  //   return () => {
  //     // setVisitLocations([]);
  //   };
  // }, [visits]);

  const limit = getActiveQuery(location, 'visitedLimit');
  const search = getActiveQuery(location, 'visitedSearch');
  const page = getActiveQuery(location, 'visitedPage');
  const sortBy = getActiveQuery(location, 'visitedSortBy');
  const sortOrder = getActiveQuery(location, 'visitedSortOrder');

  const visitedLocationQuery = useVisitedLocations(
    routeId,
    {
      limit,
      search,
      page,
      sortBy,
      sortOrder,
    },
    !!routeId,
  );

  const visitedLocationColumns = useMemo(
    () => [
      columnHelper.accessor('sl_no', {
        id: 'sl_no',
        cell: info => generateSlNo(info.row.index, page, limit),
        header: '#',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('location.dbaName', {
        id: 'location.dbaName',
        cell: info =>
          info.row.original.location && info.row.original.location.dbaName
            ? info.row.original.location.dbaName
            : 'NA',
        header: 'Location',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('actually_visited', {
        id: 'actually_visited',
        cell: info => (info.row.original.actually_visited ? 'Yes' : 'No'),
        header: 'Actually visited',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('arrival', {
        id: 'arrival',
        cell: info =>
          info.row.original.arrival
            ? moment(info.row.original.arrival).format(DATE_FORMAT_FOURTH)
            : 'NA',
        header: 'Arrived',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('departure', {
        id: 'departure',
        cell: info =>
          info.row.original.departure
            ? moment(info.row.original.departure).format(DATE_FORMAT_FOURTH)
            : 'NA',
        header: 'Departure',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('doNotVisit', {
        id: 'doNotVisit',
        cell: info => (info.row.original.doNotVisit ? 'Yes' : 'No'),
        header: 'Do Not Visit',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('note', {
        id: 'note',
        cell: info => (info.row.original.note ? info.row.original.note : 0),
        header: 'Note',
        enableColumnFilter: false,
      }),
    ],
    [columnHelper],
  );

  return (
    <Table
      columns={visitedLocationColumns}
      data={
        !visitedLocationQuery.isLoading && visitedLocationQuery.isFetching
          ? []
          : visitedLocationQuery.data && visitedLocationQuery.data.data
          ? visitedLocationQuery.data.data
          : []
      }
      placeholder="Search normal"
      searchKey="visitedSearch"
      limitKey="visitedLimit"
      pageKey="visitedPage"
      sortByKey="visitedSortBy"
      sortOrderKey="visitedSortOrder"
      totalItems={
        visitedLocationQuery.data && visitedLocationQuery.data.totalDocs
          ? visitedLocationQuery.data.totalDocs
          : 0
      }
      totalPages={
        visitedLocationQuery.data && visitedLocationQuery.data.totalPage
          ? visitedLocationQuery.data.totalPage
          : 0
      }
      isLoading={
        visitedLocationQuery.isLoading || visitedLocationQuery.isFetching
      }
    />
  );
};

export default RouteVisitedLocations;
