import { userLogin, getLogin } from '../requests/userRequest';
import { AUTH, TOAST, BRANCH_OFFICE } from './index';
import {
  fetchBranchOffices,
  fetchOfficeDetails,
} from 'store/requests/officeRequests';

// import { chatInit } from '../../pages/chat/chat-helper';
export const userLoginAction = (data, history) => async dispatch => {
  let userLoginResp = await userLogin(data);
  let user = userLoginResp.data.data.user;
  let token = userLoginResp.data.data.token;
  if (['admin', 'office-manager'].indexOf(user.role) > -1) {
    if (user.role === 'office-manager' && !user.officeId) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message:
            "You don't have a office assigned, please contact to admin !",
          show: true,
        },
      });
    } else if (user.role === 'office-manager' && user.officeId) {
      localStorage.setItem('office-id', user.officeId);
    }
    localStorage.setItem('auth-token', token);
    localStorage.setItem('role', user.role);
    dispatch({ type: AUTH.LOGIN, load: user });
    return history.push('/dashboard');
  } else {
    dispatch({
      type: TOAST.SHOW,
      load: {
        type: 'error',
        title: 'Error',
        message: "You don't have access please contact to admin !",
        show: true,
      },
    });
  }
};

export const checkLogin = () => async dispatch => {
  let userLoginResp = await getLogin();
  dispatch({ type: AUTH.INITIAL_LOAD, load: true });

  if (!userLoginResp || userLoginResp.error) {
    dispatch({
      type: TOAST.SHOW,
      load: {
        type: 'error',
        title: 'Error',
        message: 'You have no login, Please login again!',
        show: true,
      },
    });
    window.location.href = '/';
  } else {
    let user = userLoginResp.data;

    dispatch({ type: AUTH.LOGIN, load: user });
    if (user.role !== 'admin' && user.officeId) {
      getBranchOffices(user.officeId);
    }
    if (window.location.pathname === '/') {
      window.location.href = '/dashboard';
    }
    return true;
  }
};

export const getBranchOffices = officeId => async dispatch => {
  let resp = await fetchBranchOffices(officeId);
  let rootOfficeResp = await fetchOfficeDetails(officeId);

  if (resp && resp.data) {
    resp.data = resp.data.data.map(items => items.branch);
    if (rootOfficeResp && rootOfficeResp.data) {
      resp.data.unshift(rootOfficeResp.data);
    }
    dispatch({ type: BRANCH_OFFICE, load: resp.data });
  }
};

export const changeOffice = officeId => async dispatch => {
  localStorage.setItem('select-brach-office-id', officeId);
  dispatch({ type: AUTH.CHANGE_OFFICE, load: officeId });
};

export const logoutUser = role => async dispatch => {
  localStorage.clear();
  window.location.href = '/';
};

export const logoutOfficeUser = () => {
  localStorage.removeItem('office-manager-access-token');
  window.location.reload();
};
