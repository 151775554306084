import React, { useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
// import { getVisitedRouteLocation } from '../../store/requests/routeRequest';
import { createColumnHelper } from '@tanstack/react-table';
import Table from '../../components/shared/table/Table';
import { DATE_FORMAT_FOURTH } from '../../utils/constants';
import { getActiveQuery } from '../../utils';
import { useVisitedLocations } from '../../apis/queries/users.queries';

const VisitedLocationList = ({ show, onClose, selectedRouteId }) => {
  const columnHelper = createColumnHelper();
  const location = useLocation();

  const limit = getActiveQuery(location, 'visitedLimit');
  const search = getActiveQuery(location, 'visitedSearch');
  const page = getActiveQuery(location, 'visitedPage');
  const sortBy = getActiveQuery(location, 'visitedSortBy');
  const sortOrder = getActiveQuery(location, 'visitedSortOrder');

  const visitedLocationQuery = useVisitedLocations(
    selectedRouteId,
    {
      limit,
      search,
      page,
      sortBy,
      sortOrder,
    },
    show && !!selectedRouteId,
  );



  const onRequestClose = () => {
    onClose();
  };



  const visitedLocationColumns = useMemo(
    () => [
      columnHelper.accessor('location.dbaName', {
        id: 'location.dbaName',
        cell: info =>
          info.row.original.location && info.row.original.location.dbaName
            ? info.row.original.location.dbaName
            : 'NA',
        header: 'Location',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('actually_visited', {
        id: 'actually_visited',
        cell: info => (info.row.original.actually_visited ? 'Yes' : 'No'),
        header: 'Actually visited',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('arrival', {
        id: 'arrival',
        cell: info =>
          info.row.original.arrival
            ? moment(info.row.original.arrival).format(DATE_FORMAT_FOURTH)
            : 'NA',
        header: 'Arrived',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('departure', {
        id: 'departure',
        cell: info =>
          info.row.original.departure
            ? moment(info.row.original.departure).format(DATE_FORMAT_FOURTH)
            : 'NA',
        header: 'Departure',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('doNotVisit', {
        id: 'doNotVisit',
        cell: info => (info.row.original.doNotVisit ? 'Yes' : 'No'),
        header: 'Do Not Visit',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('location.type', {
        id: 'location.type',
        cell: info =>
          info.row.original.location && info.row.original.location.type
            ? info.row.original.location.type
            : 0,
        header: 'Type',
        enableColumnFilter: false,
      }),
    ],
    [columnHelper],
  );

  return (
    <Modal
      isOpen={show}
      toggle={onRequestClose}
      size="lg"
      className="visited-location-modal"
    >
      <ModalHeader toggle={onRequestClose}>{'Visited Locations'}</ModalHeader>
      <ModalBody>
        <Table
          columns={visitedLocationColumns}
          data={
            !visitedLocationQuery.isLoading && visitedLocationQuery.isFetching
              ? []
              : visitedLocationQuery.data && visitedLocationQuery.data.data
              ? visitedLocationQuery.data.data
              : []
          }
          placeholder="Search normal"
          searchKey="visitedSearch"
          limitKey="visitedLimit"
          pageKey="visitedPage"
          sortByKey="visitedSortBy"
          sortOrderKey="visitedSortOrder"
          totalItems={
            visitedLocationQuery.data && visitedLocationQuery.data.totalDocs
              ? visitedLocationQuery.data.totalDocs
              : 0
          }
          totalPages={
            visitedLocationQuery.data && visitedLocationQuery.data.totalPage
              ? visitedLocationQuery.data.totalPage
              : 0
          }
          isLoading={
            visitedLocationQuery.isLoading || visitedLocationQuery.isFetching
          }
        />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default VisitedLocationList;
