import React, { useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Spinner,
  Row,
  Col,
} from 'reactstrap';
// import MultiSelectDropDown from 'components/MultiSelect';
import { useSelector } from 'react-redux';
// import { fetchAgents, fetchOfficeAgents } from 'store/requests/agentRequest';
// import { fetchOffices } from 'store/requests/officeRequests';
import {
  // fetchAssignableOfficeRoute,
  // fetchAssignableRoutes,
  assignRoute,
} from 'store/requests/routeRequest';
import { TOAST } from 'store/actions';
import { useDispatch } from 'react-redux';
import Select from '../../components/shared/FormInputs/Select';
import {
  useInfiniteReportsByOffices,
  useOfficeById,
} from '../../apis/queries/offices.queries';
import { debounce } from 'lodash';
import {
  useInfiniteRoutes,
  useRouteById,
} from '../../apis/queries/routes.queries';
import {
  useInfiniteUsers,
  useUserById,
} from '../../apis/queries/users.queries';
import { useQueryClient } from 'react-query';

const AssignRoutes = ({
  show,
  onClose,
  selectedRoute = '',
  selectedOffice = '',
  selectedAgent = '',
  onDone,
  selectedOfficeId,
  selectedAgentId,
  selectedRouteId,
  selectedItems,
}) => {
  const queryClient = useQueryClient();
  const userData = useSelector(state => state.auth.userData);
  const officeId = useSelector(state => state.auth.brach_office_id);
  const branchOffices = useSelector(state => state.auth.branch_offices);
  // const [agents, setAgents] = useState([]);
  // const [offices, setOffices] = useState([]);
  // const [routes, setRoutes] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();

  const [updatedOfficeList, setUpdatedOfficeList] = useState([]);
  const [updatedAgentList, setUpdatedAgentList] = useState([]);
  const [updatedRouteList, setUpdatedRouteList] = useState([]);

  const [selectedOfficeItem, setSelectedOfficeItem] = useState();
  const [selectedAgentItem, setSelectedAgentItem] = useState();
  const [selectedRouteItem, setSelectedRouteItem] = useState();

  const [searchableOfficeInput, setSearchableOfficeInput] = useState(undefined);
  const [searchableAgentInput, setSearchableAgentInput] = useState(undefined);
  const [searchableRouteInput, setSearchableRouteInput] = useState(undefined);
  const [selectedRouteItems, setSelectedRouteItems] = useState();

  const debouncedSearchableOfficeInput = debounce(
    value => setSearchableOfficeInput(value),
    500,
  );

  const debouncedSearchableAgentInput = debounce(
    value => setSearchableAgentInput(value),
    500,
  );

  const debouncedSearchableRouteInput = debounce(
    value => setSearchableRouteInput(value),
    500,
  );

  const handleSearchableOfficeInput = value =>
    debouncedSearchableOfficeInput(value);

  const handleSearchableAgentInput = value =>
    debouncedSearchableAgentInput(value);

  const handleSearchableRouteInput = value =>
    debouncedSearchableRouteInput(value);

  const officeQueryById = useOfficeById(
    selectedOfficeId,
    undefined,
    show && !!selectedOfficeId,
  );

  const agentQueryById = useUserById(
    selectedAgentId,
    {
      role: 'agent',
    },
    show && !!selectedAgentId,
  );

  const routeQueryById = useRouteById(
    selectedRouteId,
    undefined,
    show && !!selectedRouteId,
  );

  const reportsByOfficeQuery = useInfiniteReportsByOffices(
    {
      limit: 10,
      page: 1,
      search: searchableOfficeInput || undefined,
    },
    show,
  );

  const agentsQuery = useInfiniteUsers(
    {
      limit: 10,
      page: 1,
      role: 'agent',
      search: searchableAgentInput || undefined,
      officeId:
        selectedOfficeItem && selectedOfficeItem.value
          ? selectedOfficeItem.value
          : undefined,
    },
    show && !!(selectedOfficeItem && selectedOfficeItem.value),
  );

  const routesQuery = useInfiniteRoutes(
    {
      limit: 10,
      page: 1,
      search: searchableRouteInput || undefined,
    },
    show,
  );

  useEffect(() => {
    if (show && userData && Object.keys(userData).length > 0) {
      // getData();
      if (userData.role === 'office-manager' && !selectedOffice) {
        changeValue('officeId', officeId);
      }
      if (selectedOffice) {
        changeValue('officeId', selectedOffice);
      }
    }
  }, [userData, officeId, selectedOffice, show]);
  // useEffect(() => {
  //   if (userData.role === 'office-manager') {
  //     setOffices(branchOffices);
  //   }
  // }, [branchOffices]);

  // const getData = async () => {
  //   let agentResp, routeResp;
  //   if (userData.role === 'admin') {
  //     agentResp = await fetchAgents();
  //     routeResp = await fetchAssignableRoutes();
  //     let officeResp = await fetchOffices();
  //     if (officeResp.name !== 'Error') {
  //       // officeResp.data = officeResp.data.map(items => ({
  //       //   ...items,
  //       //   id: items._id,
  //       // }));
  //       // setOffices(officeResp.data);
  //     }
  //   } else if (userData.role === 'office-manager' && officeId) {
  //     agentResp = await fetchOfficeAgents(officeId);
  //     routeResp = await fetchAssignableOfficeRoute(officeId);
  //   }
  //   if (agentResp && agentResp.name !== 'Error') {
  //     setAgents(agentResp.data);
  //   }
  //   if (routeResp && routeResp.name !== 'Error') {
  //     setRoutes(routeResp.data);
  //   }
  // };

  const [formData, setFormData] = useState(data);

  const data = useMemo(() => {
    setFormData({
      officeId: selectedOffice,
      agentId: selectedAgent,
      routeId: selectedRoute,
    });
    return {
      officeId: selectedOffice,
      agentId: selectedAgent,
      routeId: selectedRoute,
    };
  }, [selectedOffice, selectedAgent, selectedRoute]);

  // useEffect(() => {
  //   setFormData({ ...formData, routeId: selectedRoute });
  // }, [selectedRoute]);

  const changeValue = (key, value) => {
    let data = { ...formData };
    if (key === 'officeId') data.agentId = null;
    data[key] = value;
    setFormData(data);
  };

  const validate = () => {
    let { officeId, agentId, routeId } = formData;
    if (!officeId)
      return { isValid: false, message: 'Please select an office' };
    else if (!agentId)
      return { isValid: false, message: 'Please select an Agent' };
    else if ((!routeId || routeId.length < 1) && !selectedRouteItems.length)
      return { isValid: false, message: 'Please select a route' };
    else return { isValid: true };
  };

  const requestClose = () => {
    if (userData.role === 'office-manager') {
      setFormData({ ...formData, agentId: '', routeId: '' });
    } else {
      setFormData({ officeId: '', agentId: '', routeId: '' });
    }
    setShowSpinner(false);
    onClose();
  };

  const onSave = async () => {
    setShowSpinner(true);
    let validateData = validate();
    if (validateData.isValid) {
      let resp = await assignRoute(
        formData.agentId,
        formData.routeId || selectedRouteItems.map(item => item.value),
      );
      if (resp.name !== 'Error') {
        dispatch({
          type: TOAST.SHOW,
          load: {
            type: 'success',
            title: 'Success',
            message: 'Route assigned',
            show: true,
          },
        });
        queryClient.invalidateQueries(['routes']);
        onDone && onDone();
        setSelectedRouteItem();
        setSelectedAgentItem();
        setSelectedOfficeItem();
        requestClose();
      }
    } else {
      setShowSpinner(false);

      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: validateData.message,
          show: true,
        },
      });
    }
  };

  const formatReportByOfficeList = () =>
    (reportsByOfficeQuery.data &&
      reportsByOfficeQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )
        .map(({ _id, name }) => ({ value: _id, label: name }))) ||
    [];

  const formatAgentList = () =>
    (agentsQuery.data &&
      agentsQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )
        .map(({ _id, displayName }) => ({
          value: _id,
          label: displayName,
        }))) ||
    [];

  const formatRouteList = () =>
    (routesQuery.data &&
      routesQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )
        .map(({ _id, name }) => ({ value: _id, label: name }))) ||
    [];

  const loadMoreReportByOffice = event => {
    reportsByOfficeQuery.hasNextPage && reportsByOfficeQuery.fetchNextPage();
    event.stopPropagation();
  };

  const loadMoreAgents = event => {
    agentsQuery.hasNextPage && agentsQuery.fetchNextPage();
    event.stopPropagation();
  };

  const loadMoreRoute = event => {
    routesQuery.hasNextPage && routesQuery.fetchNextPage();
    event.stopPropagation();
  };

  useEffect(() => {
    setUpdatedOfficeList(formatReportByOfficeList());
  }, [reportsByOfficeQuery.data]);

  useEffect(() => {
    setUpdatedAgentList(formatAgentList());
  }, [agentsQuery.data]);

  useEffect(() => {
    setUpdatedRouteList(formatRouteList());
  }, [routesQuery.data]);

  useEffect(() => {
    setSelectedOfficeItem({
      value: officeQueryById.data && officeQueryById.data.id,
      label: officeQueryById.data && officeQueryById.data.name,
    });
    changeValue('officeId', officeQueryById.data && officeQueryById.data.id);
  }, [officeQueryById.data, selectedOfficeId]);

  useEffect(() => {
    if (agentQueryById.data) {
      setSelectedAgentItem({
        value: agentQueryById.data && agentQueryById.data.id,
        label: agentQueryById.data && agentQueryById.data.displayName,
      });
      changeValue('agentId', agentQueryById.data && agentQueryById.data.id);
    }
  }, [agentQueryById.data, selectedAgentId]);

  useEffect(() => {
    if (routeQueryById.data) {
      setSelectedRouteItem({
        value: routeQueryById.data && routeQueryById.data.id,
        label: routeQueryById.data && routeQueryById.data.name,
      });
      changeValue('routeId', routeQueryById.data && routeQueryById.data.id);
    } else {
      setSelectedRouteItem();
    }
  }, [routeQueryById.data, selectedRouteId]);

  useEffect(() => {
    if (selectedItems && selectedItems.length) {
      setSelectedRouteItems(
        selectedItems.map(item => ({
          label: item.name,
          value: item._id,
        })),
      );
    }
  }, [selectedItems]);

  return (
    <Modal
      isOpen={show}
      toggle={requestClose}
      className="newModal"
      backdrop={'static'}
    >
      <ModalHeader toggle={requestClose}>{'Assign Route'}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>
            Assign to <span className="required">*</span>
          </Label>
          <Select
            options={updatedOfficeList}
            loadMore={loadMoreReportByOffice}
            placeholder="Select Office"
            onChange={val => {
              changeValue('officeId', val && val.value);
              setSelectedOfficeItem(val);
            }}
            isLoading={reportsByOfficeQuery.isFetching}
            hasNextPage={reportsByOfficeQuery.hasNextPage}
            value={selectedOfficeItem}
            onInputChange={handleSearchableOfficeInput}
            isClearable
          />
        </FormGroup>

        <Row>
          <Col xs={6}>
            <FormGroup>
              <Label>
                Select Agent <span className="required">*</span>
              </Label>
              <Select
                options={updatedAgentList}
                loadMore={loadMoreAgents}
                placeholder="Select agent"
                onChange={val => {
                  changeValue('agentId', val && val.value);
                  setSelectedAgentItem(val);
                }}
                isLoading={agentsQuery.isFetching}
                hasNextPage={agentsQuery.hasNextPage}
                value={selectedAgentItem}
                onInputChange={handleSearchableAgentInput}
                isClearable
              />
            </FormGroup>
          </Col>

          <Col xs={6}>
            <FormGroup>
              <Label>
                Select Route <span className="required">*</span>
              </Label>
              <Select
                isMulti
                options={updatedRouteList}
                loadMore={loadMoreRoute}
                placeholder="Select Route"
                onChange={val => {
                  changeValue('routeId', val && val.value);
                  setSelectedRouteItem(val);
                  setSelectedRouteItems(val);
                }}
                isLoading={routesQuery.isFetching}
                hasNextPage={routesQuery.hasNextPage}
                value={selectedRouteItem || selectedRouteItems}
                onInputChange={handleSearchableRouteInput}
                isClearable
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => requestClose()}>
          Close
        </Button>
        <Button color="primary" onClick={() => onSave()} disabled={showSpinner}>
          {showSpinner ? <Spinner size="sm" /> : 'Assign'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AssignRoutes;
