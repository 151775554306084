import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Spinner,
  Input,
} from 'reactstrap';
import { TOAST } from '../../store/actions';
import { useUpdateRoute } from '../../apis/queries/routes.queries';

const RenameRouteName = ({
  show,
  onClose,
  showSpinner = false,
  routeName = '',
  routeId,
}) => {
  const [updatedRouteName, setUpdatedRouteName] = useState();
  const dispatch = useDispatch();
  const updateRouteHandler = useUpdateRoute();

  const onSubmit = () => {
    if (!updatedRouteName)
      return dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: 'Route name is required',
          show: true,
        },
      });

    updateRouteHandler.mutate(
      {
        routeId: routeId,
        name: updatedRouteName,
      },
      {
        onSuccess: () => {
          dispatch({
            type: TOAST.SHOW,
            load: {
              type: 'success',
              title: 'Success',
              message: 'Route name updated successfully',
              show: true,
            },
          });
          onClose(updatedRouteName);
        },
      },
    );
  };

  useEffect(() => {
    setUpdatedRouteName(routeName);
  }, [routeName, show]);

  return (
    <Modal
      isOpen={show}
      toggle={onClose}
      className="newModal selectDrop"
      centered
    >
      <ModalHeader toggle={onClose}>{'Rename Route'}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>
            Route Name <span className="required">*</span>
          </Label>
          <Input
            placeholder="Route Name"
            type="text"
            name="routeName"
            value={updatedRouteName}
            onChange={e => setUpdatedRouteName(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => onClose()}>
          Close
        </Button>
        <Button color="primary" disabled={showSpinner} onClick={onSubmit}>
          {showSpinner ? <Spinner /> : 'Save'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RenameRouteName;
