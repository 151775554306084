import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  TiArrowUnsorted,
  TiArrowSortedUp,
  TiArrowSortedDown,
} from 'react-icons/ti';
import useSearch from '../../../hooks/useSearch';
import {
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from 'reactstrap';
import useSort from '../../../hooks/useSort';
import useLimit from '../../../hooks/useLimit';
import usePage from '../../../hooks/usePage';
import classNames from 'classnames';
import FilterColumnInput from '../table/FilterColumnInput';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import ReactPaginate from 'react-paginate';
import { PAGE_SIZE } from '../../../utils/constants';

const Table = forwardRef(
  (
    {
      columns,
      data,
      allowRowsSelect = false,
      showSearch = true,
      placeholder,
      searchKey,
      limitKey,
      pageKey,
      sortByKey,
      sortOrderKey,
      filtersKey = 'filters',
      totalItems,
      totalPages,
      isLoading,
      setRowItems = () => {},
    },
    ref,
  ) => {
    const [rowSelection, setRowSelection] = useState({});
    const table = useReactTable({
      columns,
      data,
      state: {
        rowSelection,
      },
      enableRowSelection: true,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      onRowSelectionChange: setRowSelection,
      manualPagination: true,
      pageCount: totalPages,
    });

    const { handleInputChange, searchInput } = useSearch(searchKey, pageKey);
    const { handleSortByColumn, currentSortBy, currentSortOrder } = useSort(
      sortByKey,
      sortOrderKey,
      undefined,
      'asc',
    );
    const { handleLimit, limit } = useLimit(limitKey);
    const { handlePage, page } = usePage(pageKey);

    const resetRowSelection = useCallback(() => setRowSelection({}), []);

    const handleRowItem = useCallback(() => {
      if (allowRowsSelect) {
        const items = table
          .getSelectedRowModel()
          .flatRows.map(item => item.original);
        setRowItems(items);
      }
    }, [setRowItems]);

    useImperativeHandle(
      ref,
      () => ({
        resetRowSelection,
      }),
      [],
    );

    useEffect(() => resetRowSelection(), [page]);
    useEffect(() => handleRowItem(), [rowSelection]);
    return (
      <article>
        <section className="table-section-input">
          {showSearch ? (
            <Input
              placeholder={placeholder || ''}
              onChange={e => handleInputChange(e.target.value)}
              defaultValue={searchInput ? searchInput.replace('+', ' ') : ''}
            />
          ) : null}
        </section>

        <section className="table-section">
          <table className="table-section-table" ref={ref}>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="table-tr">
                  {allowRowsSelect && (
                    <th className="table-th">
                      <div className="table-th-content">
                        <IndeterminateCheckbox
                          {...{
                            checked: table.getIsAllPageRowsSelected(),
                            onChange:
                              table.getToggleAllPageRowsSelectedHandler(),
                          }}
                        />
                      </div>
                    </th>
                  )}
                  {headerGroup.headers.map(head => (
                    <th key={head.id} className="table-th">
                      <div className="table-th-content">
                        <div
                          className={classNames(
                            'table-th-content-child',
                            !head.column.columnDef.header.includes('Actions')
                              ? 'min-w-170'
                              : '',
                          )}
                        >
                          {head.isPlaceholder ? null : (
                            <div
                              {...{
                                className: classNames(
                                  head.column.getCanSort() ? '' : '',
                                  'table-head-children',
                                ),
                                onClick: () =>
                                  head.column.columnDef.header.includes(
                                    'Actions',
                                  ) ||
                                  head.column.columnDef.header.includes('#')
                                    ? null
                                    : handleSortByColumn(head.id),
                              }}
                            >
                              {flexRender(
                                head.column.columnDef.header,
                                head.getContext(),
                              )}
                            </div>
                          )}

                          {head.column.columnDef.header.includes('Actions') ||
                          head.column.columnDef.header.includes(
                            '#',
                          ) ? null : currentSortOrder === 'asc' &&
                            currentSortBy === head.id ? (
                            <TiArrowSortedUp />
                          ) : currentSortOrder === 'desc' &&
                            currentSortBy === head.id ? (
                            <TiArrowSortedDown />
                          ) : (
                            <TiArrowUnsorted />
                          )}
                        </div>
                        <div>
                          {head.column.getCanFilter() ? (
                            <FilterColumnInput
                              placeholder={head.column.columnDef.header}
                              searchKey={filtersKey}
                              columnKey={head.column.columnDef.id}
                            />
                          ) : null}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="table-body-tr">
                  {allowRowsSelect && (
                    <td className="table-body-td">
                      <div className="table-body-td-content">
                        <IndeterminateCheckbox
                          {...{
                            checked: row.getIsSelected(),
                            onChange: row.getToggleSelectedHandler(),
                          }}
                        />
                      </div>
                    </td>
                  )}
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="table-body-td">
                      <div
                        className={classNames(
                          !cell.column.columnDef.header.includes('Actions')
                            ? 'table-td-content'
                            : '',
                        )}
                        title={cell.getValue()}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          {isLoading ? (
            <div className="table-no-data">
              <Spinner size="lg" color="primary" />
            </div>
          ) : null}
          {!isLoading && !data.length ? (
            <div className="table-no-data">No Data Found</div>
          ) : null}
        </section>

        {data.length ? (
          <section className="table-footer-content">
            <span className="per-page-content">Per page item:</span>
            <UncontrolledDropdown>
              <DropdownToggle
                caret
                className="buttonGroup__root page-link button-drop-down button-limit"
              >
                {limit === totalItems.toString() ? 'All' : limit}
              </DropdownToggle>
              <DropdownMenu>
                {PAGE_SIZE.map(pageSize => (
                  <DropdownItem
                    key={pageSize}
                    onClick={() => {
                      if (pageSize === 'All') {
                        handleLimit(totalItems);
                        return;
                      }
                      handleLimit(pageSize);
                    }}
                  >
                    {pageSize}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <span className="total-item-content">
              of total items {totalItems}
            </span>
            <div className="pagination-content">
              {/* <button type="button" className="border-2 border-gray-100 px-3">
            |&lt;
          </button> */}
              <ReactPaginate
                previousLabel="<<"
                nextLabel=">>"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={totalPages || 1}
                pageRangeDisplayed={4}
                onPageChange={currentValue =>
                  handlePage(currentValue.selected + 1)
                }
                marginPagesDisplayed={1}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page - 1}
              />
              {/* <button type="button" className="border-2 border-gray-100 px-3">
            &gt;|
          </button> */}
            </div>
          </section>
        ) : null}
      </article>
    );
  },
);

export default Table;
