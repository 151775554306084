import { useInfiniteQuery } from 'react-query';
import httpServices from '../../common/service';
import { getUrl } from './checkRole';
import moment from 'moment';
import { fetchPaginatedRouteLocations } from '../../apis/requests/locations.requests';

const url = process.env.REACT_APP_BASE_URL;

export const fetchOfficeRoute = async (id, createdById = null) => {
  try {
    let uri = `${url}/routes`;
    let filter = {
      where: {
        status: { neq: 'inactive' },
      },
      include: [
        { relation: 'createdBy' },
        { relation: 'routeLocations' },
        {
          relation: 'user',
          scope: {
            include: [
              {
                relation: 'office',
              },
            ],
          },
        },
      ],
    };
    if (createdById) {
      filter.where.createdById = createdById;
    }
    uri += `?filter=${JSON.stringify(filter)}`;
    if (id) {
      uri += `&officeId=${id}`;
    }
    let officeResp = await httpServices.httpGetCall(uri);
    return officeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
export const fetchAssignableOfficeRoute = async id => {
  try {
    let uri = `${url}/offices/${id}/unassigned-routes`;

    let officeResp = await httpServices.httpGetCall(uri);
    return officeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchAssignedOfficeRoutes = async (id, limit, offset) => {
  try {
    let uri = `${url}/offices/${id}/assigned-routes`;
    let filter = {
      include: [
        {
          relation: 'visit',
        },
      ],
    };
    uri += `?filter=${JSON.stringify(filter)}`;
    if (limit) {
      uri += `&limit=${limit}`;
    }
    if (offset) {
      uri += `&skip=${limit}`;
    }
    let officeResp = await httpServices.httpGetCall(uri);
    return officeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchAssignedOfficeRoutesCounts = async id => {
  try {
    let uri = `${url}/offices/${id}/assigned-routes/count`;

    let officeResp = await httpServices.httpGetCall(uri);
    return officeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchAssignableRoutes = async id => {
  try {
    let uri = `${url}/routes`;

    let filter = {
      where: {
        userId: null,
        status: { neq: 'inactive' },
      },
    };
    uri += `?filter=${JSON.stringify(filter)}`;
    let officeResp = await httpServices.httpGetCall(uri);
    return officeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchAllRoutes = async createdById => {
  try {
    let uri = `${url}/${getUrl('routes')}`;

    let filter = {
      include: [
        { relation: 'createdBy' },
        {
          relation: 'visits',
          scope: {
            order: 'arrival DESC',
          },
        },
        { relation: 'routeLocations' },
        {
          relation: 'user',
          scope: {
            include: [
              {
                relation: 'office',
              },
            ],
          },
        },
      ],
    };
    if (createdById) {
      filter.where.createdById = createdById;
    }
    uri += `?filter=${JSON.stringify(filter)}`;

    let officeResp = await httpServices.httpGetCall(uri);
    return officeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchAllActiveRoutes = async (officeId, dateRange) => {
  try {
    let uri = `${url}/users/active-routes`;

    if (officeId) {
      uri += `?officeId=${officeId}`;
    }
    if (!officeId && dateRange)
      uri += `?from=${moment(dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss',
      )}&to=${moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss')}`;
    else if (officeId && dateRange)
      uri += `&from=${moment(dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss',
      )}&to=${moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss')}`;
    let officeResp = await httpServices.httpPostCall(uri);
    return officeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchCurrentActiveRoute = async userId => {
  try {
    let uri = `${url}/${getUrl('routes')}`;

    let filter = {
      where: {
        status: 'active',
        userId: userId,
      },
      include: [
        {
          relation: 'routeLocations',
          scope: {
            include: [
              {
                relation: 'location',
              },
            ],
          },
        },
        { relation: 'user' },
      ],
    };

    uri += `?filter=${JSON.stringify(filter)}`;
    let officeResp = await httpServices.httpGetCall(uri);
    return officeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const updateRoute = async (id, data) => {
  try {
    let uri = `${url}/routes/${id}`;
    let resp = await httpServices.httpPatchCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchRouteDetails = async id => {
  try {
    let uri = `${url}/routes/${id}`;
    let filter = {
      include: [
        {
          relation: 'user',
          scope: {
            include: [{ relation: 'office' }],
          },
        },
      ],
    };
    uri += `?filter=${JSON.stringify(filter)}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchUserRouteDetails = async id => {
  try {
    let uri = `${url}/users/route-details/${id}`;

    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchRouteLocations = async (id, query = {}) => {
  try {
    let uri = `${url}/route-location-maps`;
    let filter = {
      where: {
        routeId: id,
        ...query,
      },
      include: [
        {
          relation: 'location',
          scope: { include: [{ relation: 'locationType' }] },
        },
      ],
      order: 'order ASC',
    };
    uri += `?filter=${JSON.stringify(filter)}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const useInfinitePaginatedRouteLocations = (query, enabled = true) =>
  useInfiniteQuery({
    queryKey: ['route-location-maps-paginated', query],
    queryFn: async ({ pageParam }) => {
      const queries = query;
      if (pageParam) {
        queries.page = pageParam || 1;
      }

      const res = await fetchPaginatedRouteLocations(queries);
      return res;
    },
    getNextPageParam: lastPage =>
      lastPage && lastPage.hasNextPage ? (lastPage.page || 0) + 1 : undefined,
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const fetchRouteTemplates = async userId => {
  try {
    let uri = `${url}/users/${userId}/route-templates`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchLocationsByFilter = async filter => {
  try {
    const {
      latitude,
      longitude,
      limit,
      kilometers,
      params = {},
      officeId = null,
      userId = null,
    } = filter;
    params.status = { neq: 'inactive' };
    let uri = `${url}/locations/filter?latitude=${latitude}&longitude=${longitude}&limit=${limit}&kilometers=${kilometers}&params=${JSON.stringify(
      params,
    )}`;
    if (officeId) {
      uri = `${uri}&officeId=${officeId}`;
    }
    if (userId) {
      uri = `${uri}&userId=${userId}`;
    }
    let resp = await httpServices.httpPostCall(uri, null);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const addRoute = async (
  name,
  locations,
  createdById,
  distance,
  officeId,
  estimatedTime,
  userId,
) => {
  try {
    let uri = `${url}/routes/create?name=${name}&createdById=${createdById}&userId=${userId}&distance=${distance}&locations=${JSON.stringify(
      locations,
    )}`;
    if (officeId) uri += `&officeId=${officeId}`;
    if (estimatedTime) uri += `&estimatedTime=${estimatedTime}`;
    let resp = await httpServices.httpPostCall(uri, null);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const copyRoute = async (id, type = 'full') => {
  try {
    let uri = `${url}/users/copy-route/${id}/${type}`;

    let resp = await httpServices.httpPostCall(uri, null);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const assignRoute = async (id, data) => {
  try {
    let uri = `${url}/users/${id}/assign-routes`;
    if (typeof data === 'string') data = [data];
    let resp = await httpServices.httpPostCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const unlinkRoutes = async (routeId, locationId) => {
  try {
    let uri = `${url}/route-location-maps/unlink/${routeId}/${locationId}`;
    let resp = await httpServices.httpDeleteCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const createRouteTemplate = async (userId, body) => {
  try {
    let uri = `${url}/users/${userId}/route-templates`;
    let resp = await httpServices.httpPostCall(uri, body);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const deleteRouteTemplate = async id => {
  try {
    let uri = `${url}/route-templates/${id}`;
    let resp = await httpServices.httpDeleteCall(uri, null);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const addRouteLocation = async (routeId, order, body) => {
  try {
    let uri = `${url}/routes/${routeId}/add-location-after/${order}`;
    let resp = await httpServices.httpPostCall(uri, body);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const unassignRoutes = async (data, from) => {
  try {
    let uri = `${url}/routes/unassign-from/${from}?ids=${JSON.stringify({
      ids: data,
    })}`;
    let resp = await httpServices.httpPostCall(uri, null);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const existingLocationAfter = async (locationId, routeId, order) => {
  try {
    let uri = `${url}/routes/${routeId}/add-existing-location-after/${locationId}/${order}`;
    let resp = await httpServices.httpPostCall(uri, null);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const getVisitedRouteLocation = async routeId => {
  try {
    let uri = `${url}/users/route-location-details/${routeId}`;
    let userData = await httpServices.httpGetCall(uri);
    return userData;
  } catch (err) {
    return err;
  }
};
