import React, { useState } from 'react';
import {
  Card,
  Col,
  Row,
  Spinner,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import { verifyCode } from 'store/requests/userRequest';

import { useDispatch, useSelector } from 'react-redux';
// import { userLoginAction } from '../store/actions/authAction';
import { TOAST } from 'store/actions';
import logo200Image from 'assets/img/logo/ExpiTrack.png';
import Typography from 'components/Typography';

// class AuthPage extends React.Component {
const VerifyOtp = ({ authState }) => {
  const [otp, setOtp] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);

  const initial_loading = useSelector(state => state.auth.initial_load);

  const validateForm = () => {
    if (!validator.isLength(otp, { min: 6, max: 6 })) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: 'Invalid otp!',
          show: true,
        },
      });

      return false;
    } else {
      return true;
    }
  };
  const verifyKey = async e => {
    if (e) e.preventDefault();
    setShowLoader(true);

    let isValid = validateForm();
    if (isValid) {
      let resp = await verifyCode(
        localStorage.getItem('forgot-password-email'),
        otp,
      );
      if (resp && resp.name !== 'Error') {
        dispatch({
          type: TOAST.SHOW,
          load: {
            type: 'success',
            title: 'Success',
            message: 'Otp verified',
            show: true,
          },
        });
        localStorage.setItem('forgot-password-otp', otp);
        history.push('reset-password');
      }
    }
    setShowLoader(false);
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#28376E',
      }}
    >
      <Col md={6} lg={4}>
        {initial_loading ? (
          <Spinner
            size="lg"
            animation="border"
            className="page-spinner"
            variant="info"
          />
        ) : (
          <div>
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 150, height: 30, cursor: 'pointer' }}
                alt="logo"
                // onClick={onLogoClick}
              />
            </div>
            <Card
              body
              style={{
                borderRadius: 10,
                paddingVertical: 30,
                paddingLeft: 50,
                paddingRight: 50,
              }}
            >
              <Typography type="h5" className="font-weight-bold text-center">
                Verify otp
              </Typography>
              <Form onSubmit={() => verifyKey()}>
                <FormGroup>
                  <Label for={'otp'} className="font-weight-bold">
                    OTP
                  </Label>
                  <Input
                    placeholder="00000"
                    onChange={e => setOtp(e.target.value)}
                  />
                </FormGroup>
                <Button
                  color="primary"
                  className="pull-right"
                  onClick={() => verifyKey()}
                >
                  {showLoader ? <Spinner size="sm" /> : 'Verify'}
                </Button>
              </Form>
            </Card>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default VerifyOtp;
