import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { getActiveQuery } from '../utils';

const useSearch = (
  searchKey = 'name',
  pageKey = 'page',
  debounceDelay = 500,
) => {
  const history = useHistory();
  const location = useLocation();

  const searchInput = getActiveQuery(location, searchKey);

  const debouncedSearchInput = _.debounce(value => {
    const newSearchParams = new URLSearchParams(location.search);

    if (value === '') {
      newSearchParams.delete(searchKey);
    } else {
      newSearchParams.set(searchKey, value);
    }
    newSearchParams.set(pageKey, '1');
    history.replace({
      search: newSearchParams.toString(),
    });
  }, debounceDelay);

  const handleInputChange = value => debouncedSearchInput(value);

  return {
    handleInputChange,
    searchInput,
  };
};

export default useSearch;
