import { AUTH, BRANCH_OFFICE } from '../actions';

const initialState = {
  userData: {},
  initial_load: false,
  brach_office_id: '',
  branch_offices: [],
  parent_office_id: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH.LOGIN:
      return { ...state, userData: action.load };
    case AUTH.INITIAL_LOAD:
      return { ...state, initial_load: action.load };
    case AUTH.CHANGE_OFFICE:
      return { ...state, brach_office_id: action.load };
    case BRANCH_OFFICE:
      return { ...state, branch_offices: action.load };
    default:
      return state;
  }
};
