import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './authReducer';
import toastReducer from './toastReducer';
import chatReducer from './chatReducer';

import { composeWithDevTools } from 'redux-devtools-extension';

const allReducers = combineReducers({
  auth: authReducer,
  toast: toastReducer,
  chat: chatReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return allReducers(state, action);
};

// TODO: add react-native-dotenv support here and prevent redux-logger for production build
const configureStore = () =>
  createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default configureStore;
