import urlcat from 'urlcat';
import http from '../http';

export const fetchReportsByOffices = query =>
  http.get(urlcat('/offices/report', query));

export const fetchOfficeById = (officeId, query) =>
  http.get(urlcat(`/offices/${officeId}`, query));

export const fetchLocationsByOfficeId = (officeId, query) =>
  http.get(urlcat(`/offices/${officeId}/locations`, query));

export const fetchRoutesByOfficeId = (officeId, query) =>
  http.get(urlcat(`/offices/${officeId}/assigned-routes`, query));

export const fetchAgentsByOfficeId = (officeId, query) =>
  http.get(urlcat(`/offices/${officeId}/users`, query));

export const exportLocations = id => http.get(`/office/${id}/export-locations`);

export const exportRoutes = id => http.get(`/office/${id}/export-routes`);

export const exportAgents = id => http.get(`/offices/${id}/export-users`);

export const exportSubOffices = id =>
  http.get(`/office/${id}/export-suboffice`);
