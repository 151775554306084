import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
const ConfirmModal = ({
  show,
  onClose,
  title,
  body,
  actions,
  className = '',
}) => {
  return (
    <Modal isOpen={show} toggle={onClose} className={className}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        {actions ? (
          actions.map((items, index) => (
            <React.Fragment key={`item-${index}`}>{items}</React.Fragment>
          ))
        ) : (
          <Button color="secondary">Close</Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
