import {
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { createContext, useMemo, useState } from 'react';
import {
  useUpdateLocationOrder,
  useUpdateRoute,
} from '../../apis/queries/routes.queries';
import { getUnOptimizedRoute } from '../../store/requests/directionRequest';
import { TOAST } from '../../store/actions';
import { useDispatch } from 'react-redux';

const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() {},
});

const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};
export const SortableItem = ({ children, id, status }) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id, disabled: status === 'completed' });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );

  const style = {
    opacity: isDragging ? 0 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div {...attributes} {...listeners}>
      <SortableItemContext.Provider value={context}>
        <li className="SortableItem" ref={setNodeRef} style={style}>
          {children}
        </li>
      </SortableItemContext.Provider>
    </div>
  );
};
const DragAndDrop = ({
  locations,
  setLocations,
  renderItem,
  routeId,
  getDetails,
}) => {
  const changeLocationOrderHandler = useUpdateLocationOrder();
  const updateRouteHandler = useUpdateRoute();
  const dispatch = useDispatch();

  function SortableOverlay({ children }) {
    return (
      <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
    );
  }

  function SortableList() {
    const [active, setActive] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const activeItem = useMemo(
      () => locations.find(item => active && item.id === active.id),
      [active, locations],
    );
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    );

    const onDragEnd = async (active, over) => {
      if (over && active.id !== over.id) {
        const activeIndex = locations.findIndex(({ id }) => id === active.id);
        const overIndex = locations.findIndex(({ id }) => id === over.id);
        const activeItem = locations.filter(({ id }) => id === active.id)[0];
        const overItem = locations.filter(({ id }) => id === over.id)[0];

        changeLocationOrderHandler.mutate({
          routeId,
          locationId: activeItem.locationId,
          order:
            activeItem.order > overItem.order
              ? overItem.order
              : overItem.order + 1,
        });
        const formattedLocations = arrayMove(locations, activeIndex, overIndex);
        setLocations(formattedLocations);

        let points = formattedLocations.map(
          items => items.locations.location.coordinates,
        );

        let resp = await getUnOptimizedRoute(points);

        if (!resp || !resp.paths || !resp.paths.length) {
          dispatch({
            type: TOAST.SHOW,
            load: {
              type: 'error',
              title: 'Error',
              message: resp.message,
              show: true,
            },
          });

          return;
        }

        updateRouteHandler.mutate(
          {
            routeId,
            estimatedTime: parseInt(resp.paths[0].time / 1000),
            distance: resp.paths[0].distance,
          },
          {
            onSuccess: () => {
              getDetails();
            },
          },
        );
      }
      setActive(null);
      setActiveIndex(null);
    };

    return (
      <DndContext
        sensors={sensors}
        onDragStart={({ active }) => {
          const idx = locations.findIndex(item => item.id === active.id);

          setActive(active);
          setActiveIndex(idx);
        }}
        onDragEnd={({ active, over }) => onDragEnd(active, over)}
        onDragCancel={() => {
          setActive(null);
          setActiveIndex(null);
        }}
      >
        <SortableContext items={locations}>
          <ul className="SortableList" role="application">
            {locations.map((item, idx) => (
              <React.Fragment key={item.id}>
                {renderItem(item, idx)}
              </React.Fragment>
            ))}
          </ul>
        </SortableContext>
        <SortableOverlay>
          {activeItem ? renderItem(activeItem, activeIndex) : null}
        </SortableOverlay>
      </DndContext>
    );
  }

  return <SortableList locations={locations} onChange={setLocations} />;
};

export default DragAndDrop;
