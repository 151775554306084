import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from '@react-google-maps/api';
const exampleMapStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];
const Map = ({
  lat = 30.3323,
  lng = 78.0,
  height,
  width,
  markers = [],
  showCenter = false,
  getDistance = () => {},
  track = false,
  origin,
  destination,
}) => {
  const [center, setCenter] = useState({ lat: lat, lng: lng });
  const [mapRef, setMapRef] = useState(null);
  const [enableDirections, setEnableDirections] = useState(false);
  const [route, setRoute] = useState({ origin: {}, destination: {} });
  const [waypoints, setWaypoints] = useState([]);
  const [direction, setDirection] = useState();
  useEffect(() => {
    if (Array.isArray(markers) && markers.length > 0 && markers[0]) {
   

      setCenter({
        lat: markers[0].pos.lat,
        lng: markers[0].pos.lng,
      });
      setRoute({
        origin: markers[0].pos,
        destination: markers[markers.length - 1].pos,
      });
      if (markers.length >= 2) {
        let waypointList = [...markers];
        waypointList.shift();
        waypointList.pop();
        const list = waypointList.map(item => {
          return { location: item.pos, stopover: true };
        });
        setWaypoints(list);
        setEnableDirections(true);
      }

      fitBounds(mapRef);
    } else {
      setEnableDirections(false);
    }
    if (origin && destination && track) setEnableDirections(true);
  }, [markers, destination, track]);

  useEffect(() => {
    if (Array.isArray(markers) && markers.length > 0) {
      return;
    }
    if (lat && lng) {
      setCenter({
        lat: lat,
        lng: lng,
      });
      loadHandler(mapRef);
    }
  }, [lat, lng, mapRef]);

  const containerStyle = {
    width: width ? width : '100vw',
    height: height ? height : '50vh',
  };
  useEffect(() => {
    if (markers && markers.length > 1) {
      fitBounds(mapRef);
    }
  }, [markers]);

  const fitBounds = map => {
    if (!map) {
      return;
    }
    const bounds = new window.google.maps.LatLngBounds();
    markers.map(place => {
      if (!place) return null;
      bounds.extend(place.pos);
      return place.id;
    });
    map.fitBounds(bounds);
  };

  const loadHandler = map => {
    // Store a reference to the google map instance in state
    if (map) {
      setMapRef(map);
      if (showCenter) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
      }
    }
    // Fit map bounds to contain all markers
    if (Array.isArray(markers) && markers.length > 0) {
      fitBounds(map);
    }
  };

  useEffect(() => {
    fitBounds();
  }, []);
  return (
    <>
      <GoogleMap
        onLoad={loadHandler}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={1}
        // defaultZoom={5}
        options={{
          styles: exampleMapStyles,
        }}
      >
        {showCenter && lat && lng && (
          <Marker position={{ lat: center.lat, lng: center.lng }} />
        )}
        {markers.map((marker, index) => {
          if (!marker || !marker.pos) return null;
          return (
            <Marker
              key={marker.id}
              title={marker.displayName || null}
              onClick={marker.onClick ? marker.onClick : null}
              label={{
                text: (index + 1).toString(),
                color: 'white',
                fontSize: '10px',
              }}
              icon={marker.isVisited ? '/visited.svg' : '/unvisited.svg'}
              position={{ lat: marker.pos.lat, lng: marker.pos.lng }}
            />
          );
        })}
        {track && origin && (
          <Marker position={origin} icon={'/user-location.svg'} />
        )}
        {direction && track && (
          <DirectionsRenderer
            directions={direction}
            options={{
              polylineOptions: {
                strokeColor: 'black',
              },
              markerOptions: {
                visible: false,
              },
            }}
          />
        )}
      </GoogleMap>
      {enableDirections && !track && (
        <DirectionsService
          options={{
            destination: route.destination,
            origin: route.origin,
            waypoints: waypoints,
            travelMode: 'DRIVING',
          }}
          callback={response => {
            getDistance(response);
            setEnableDirections(false);
          }}
        />
      )}
      {track && enableDirections && (
        <DirectionsService
          options={{
            destination: destination,
            origin: origin,
            travelMode: 'DRIVING',
          }}
          callback={response => {
            setDirection(response);
            getDistance(response);
            setEnableDirections(false);
          }}
        />
      )}
    </>
  );
};

export default React.memo(Map);
