import React, { useState } from 'react';
import {
  Card,
  Col,
  Row,
  Spinner,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import validator from 'validator';

import { useDispatch, useSelector } from 'react-redux';
import { TOAST } from 'store/actions';
import logo200Image from 'assets/img/logo/ExpiTrack.png';
import Typography from 'components/Typography';

import { forgotPassword } from 'store/requests/userRequest';
// class AuthPage extends React.Component {
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const initial_loading = useSelector(state => state.auth.initial_load);

  const validateForm = () => {
    if (!validator.isEmail(email)) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: 'Invalid email!',
          show: true,
        },
      });

      return false;
    } else {
      return true;
    }
  };

  const submitForgot = async () => {
    setShowLoader(true);
    let isValid = validateForm();
    if (isValid) {
      //   dispatch(userLoginAction({ email, password }, history));
      let resp = await forgotPassword(email);
      if (resp && resp.name !== 'Error') {
        localStorage.setItem('forgot-password-email', email);
        dispatch({
          type: TOAST.SHOW,
          load: {
            type: 'success',
            title: 'Success',
            message: `A reset code has been sent to ${email}`,
            show: true,
          },
        });
        history.push('/verify');
      }
    }
    setShowLoader(false);
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#28376E',
      }}
    >
      <Col md={6} lg={4}>
        {initial_loading ? (
          <Spinner
            size="lg"
            animation="border"
            className="page-spinner"
            variant="info"
          />
        ) : (
          <div>
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 150, height: 30, cursor: 'pointer' }}
                alt="logo"
                // onClick={onLogoClick}
              />
            </div>
            <Card
              body
              style={{
                borderRadius: 10,
                paddingVertical: 30,
                paddingLeft: 50,
                paddingRight: 50,
              }}
            >
              <Typography type="h5" className="font-weight-bold text-center">
                Forget password
              </Typography>
              <Form>
                <FormGroup>
                  <Label for={'email'} className="font-weight-bold">
                    Email
                  </Label>
                  <Input
                    placeholder="example@gmail.com"
                    onChange={e => setEmail(e.target.value)}
                  />
                </FormGroup>
                <Button
                  color="primary"
                  className="pull-right"
                  onClick={() => submitForgot()}
                >
                  {showLoader ? <Spinner size="sm" /> : 'Send code'}
                </Button>
              </Form>
            </Card>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ForgotPassword;
