import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { getActiveQuery } from '../utils';
import { useMemo } from 'react';

const stringToObject = inputString => {
  const keyValuePairs = inputString.split(/,(?![^:]+:)/);
  const resultObject = {};

  keyValuePairs.forEach(pair => {
    const colonIndex = pair.indexOf(':');
    const key = pair.slice(0, colonIndex).trim();
    const value = pair.slice(colonIndex + 1).trim();
    resultObject[key] = value;
  });

  return resultObject;
};

const useColumnSearch = (
  searchKey = 'name',
  pageKey = 'page',
  columnKey = '',
  debounceDelay = 500,
) => {
  const history = useHistory();
  const location = useLocation();

  const debouncedSearchInput = _.debounce(value => {
    const newSearchParams = new URLSearchParams(location.search);
    const filter = newSearchParams.get(searchKey) || '';

    let stringQuery = [];
    const obj = {};

    (filter.split(',') || []).forEach(item => {
      const [k, v] = item.split(':');
      if (v) {
        obj[k] = v;
      }
    });

    obj[columnKey] = value;

    Object.keys(obj).forEach(key => {
      if (obj[key]) {
        stringQuery.push(`${key}:${obj[key]}`);
      }
    });

    if (!stringQuery.length) {
      newSearchParams.delete(searchKey);
    } else {
      newSearchParams.set(searchKey, stringQuery.join(','));
    }
    newSearchParams.set(pageKey, '1');
    history.replace({
      search: newSearchParams.toString(),
    });
  }, debounceDelay);

  const query = getActiveQuery(location, searchKey);
  const allQueries = useMemo(
    () => (query ? stringToObject(decodeURIComponent(query)) : {}),
    [query],
  );
  const handleInputChange = value => debouncedSearchInput(value);
  const searchInput = useMemo(
    () =>
      allQueries[columnKey] ? allQueries[columnKey].replace('+', ' ') : '',
    [allQueries],
  );
  return {
    handleInputChange,
    searchInput,
  };
};

export default useColumnSearch;
