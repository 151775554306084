import moment from 'moment';
import httpServices from '../../common/service';
const url = process.env.REACT_APP_BASE_URL;
export const fetchOfficeAgents = async (id, limit, offset) => {
  try {
    let uri = `${url}/offices/${id}/users`;
    let filter = {
      where: { role: 'agent' },
      include: [
        {
          relation: 'visits',
          scope: {
            order: ['arrival; DESC'],
            limit: 1,
          },
        },
        {
          relation: 'userLocations',
        },
      ],
    };
    if (limit) {
      filter.limit = limit;
    }
    if (offset) {
      filter.skip = offset;
    }
    uri += `?filter=${JSON.stringify(filter)}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
export const fetchOfficeAgentsCount = async id => {
  try {
    let uri = `${url}/offices/${id}/users/count`;
    let filter = {
      where: { role: 'agent' },
    };
    uri += `?filter=${JSON.stringify(filter)}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchAgents = async officeId => {
  try {
    let uri = `${url}/users`;
    let filter = {
      where: {
        role: 'agent',
        status: 'active',
      },
      include: [
        {
          relation: 'visits',
          scope: {
            order: ['arrival; DESC'],
            limit: 1,
          },
        },
        { relation: 'office' },
        {
          relation: 'routes',
          scope: {
            where: {
              status: 'active',
            },
          },
        },
        {
          relation: 'userLocations',
        },
      ],
    };

    uri += `?filter=${JSON.stringify(filter)}`;
    if (officeId) {
      uri += `&officeId=${officeId}`;
    }
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const updateAgent = async (id, data) => {
  try {
    let uri = `${url}/users/${id}`;
    let resp = await httpServices.httpPatchCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const addAgent = async data => {
  try {
    let uri = `${url}/users`;
    let resp = await httpServices.httpPostCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchAgentDetails = async id => {
  try {
    let uri = `${url}/users/${id}`;
    let filter = {
      include: [{ relation: 'office' }],
    };
    uri += `?filter=${JSON.stringify(filter)}`;

    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const assignLocationAgent = async (id, data) => {
  try {
    let uri = `${url}/users/assign-user-locations/${id}`;
    let resp = await httpServices.httpPostCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const assignLocationOffice = async (id, data) => {
  try {
    let uri = `${url}/users/assign-office-locations/${id}`;
    let resp = await httpServices.httpPostCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchAgentReport = async filter => {
  try {
    let uri = `${url}/users/activity-report${filter}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchTopAgent = async (officeId, dateRange) => {
  try {
    let uri = `${url}/users/top-agents`;
    if (officeId) uri += `?officeId=${officeId}`;
    if (!officeId && dateRange)
      uri += `?from=${moment(dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss',
      )}&to=${moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss')}`;
    else if (officeId && dateRange)
      uri += `&from=${moment(dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss',
      )}&to=${moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss')}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
