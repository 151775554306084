import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
const UnassignModal = ({
  show,
  onClose,
  title,
  body,
  onSubmit,
  className = '',
  showRouteUnassign = true,
  btnTxt = 'Unassign',
}) => {
  const [unassignRoute, setUnassignRoute] = useState(false);
  const [unAssignFromAgent, setUnAssignFromAgent] = useState(false);
  const [unAssignFromOffice, setUnAssignFromOffice] = useState(false);
  const handleOnSubmit = () => {
    if (showRouteUnassign)
      onSubmit({ unassignRoute, unAssignFromAgent, unAssignFromOffice });
    else onSubmit(unassignRoute);
  };
  return (
    <Modal
      isOpen={show}
      toggle={() => {
        onClose();
        setUnassignRoute(false);
      }}
      className={className}
      backdrop={'static'}
    >
      <ModalHeader
        toggle={() => {
          onClose();
          setUnassignRoute(false);
        }}
      >
        {title}
      </ModalHeader>
      <ModalBody>
        {body}
        {showRouteUnassign && (
          <>
            <div className="mt-2">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    // value={unassignRoute}
                    onChange={e => {
                      setUnassignRoute(e.target.checked);
                    }}
                  />{' '}
                  <span className="ml-2">Unassign from route</span>
                </Label>
              </FormGroup>
            </div>
            <div className="mt-2">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    // value={unAssignFromAgent}
                    onChange={e => setUnAssignFromAgent(e.target.checked)}
                  />{' '}
                  <span className="ml-2">Unassign from agent</span>
                </Label>
              </FormGroup>
            </div>
            <div className="mt-2">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    // value={unAssignFromOffice}
                    onChange={e => setUnAssignFromOffice(e.target.checked)}
                  />{' '}
                  <span className="ml-2">Unassign from office</span>
                </Label>
              </FormGroup>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          key="button-1"
          onClick={() => {
            setUnassignRoute(false);
            handleOnSubmit(unassignRoute);
          }}
          color="danger"
        >
          {btnTxt}
        </Button>
        <Button
          key="button-2"
          color="primary"
          onClick={() => {
            setUnassignRoute(false);
            setUnAssignFromAgent(false);
            setUnAssignFromOffice(false);
            onClose();
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UnassignModal;
