import React, { useState } from 'react';
import {
  Card,
  Col,
  Row,
  Spinner,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import validator from 'validator';

import { useDispatch, useSelector } from 'react-redux';
// import { userLoginAction } from '../store/actions/authAction';
import { TOAST } from 'store/actions';
import logo200Image from 'assets/img/logo/ExpiTrack.png';
import Typography from 'components/Typography';
import { resetPassword } from 'store/requests/userRequest';

// class AuthPage extends React.Component {
const ResetPassword = ({ authState }) => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);

  const handleOnChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const initial_loading = useSelector(state => state.auth.initial_load);

  const validateForm = () => {
    if (!validator.isLength(formData.password, { min: 6 })) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: 'Password length should be at least 6',
          show: true,
        },
      });

      return false;
    } else if (formData.password !== formData.confirmPassword) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: "Confirm password doesn't match",
          show: true,
        },
      });

      return false;
    } else {
      return true;
    }
  };

  const submitPassword = async () => {
    setShowLoader(true);

    let isValid = validateForm();
    if (isValid) {
      let resp = await resetPassword(
        localStorage.getItem('forgot-password-email'),
        localStorage.getItem('forgot-password-otp'),
        formData.password,
      );
      if (resp && resp.name !== 'Error') {
        dispatch({
          type: TOAST.SHOW,
          load: {
            type: 'success',
            title: 'Success',
            message: 'Password reset',
            show: true,
          },
        });
        localStorage.clear();
        history.push('/');
      }
    }
    setShowLoader(false);
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#28376E',
      }}
    >
      <Col md={6} lg={4}>
        {initial_loading ? (
          <Spinner
            size="lg"
            animation="border"
            className="page-spinner"
            variant="info"
          />
        ) : (
          <div>
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 150, height: 30, cursor: 'pointer' }}
                alt="logo"
                // onClick={onLogoClick}
              />
            </div>
            <Card
              body
              style={{
                borderRadius: 10,
                paddingVertical: 30,
                paddingLeft: 50,
                paddingRight: 50,
              }}
            >
              <Typography type="h5" className="font-weight-bold text-center">
                Reset Password
              </Typography>
              <Form>
                <FormGroup>
                  <Label for={'password'} className="font-weight-bold">
                    Password
                  </Label>
                  <Input
                    placeholder="********"
                    onChange={e => handleOnChange('password', e.target.value)}
                    type="password"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for={'email'} className="font-weight-bold">
                    Confirm password
                  </Label>
                  <Input
                    placeholder="*********"
                    onChange={e =>
                      handleOnChange('confirmPassword', e.target.value)
                    }
                    type="password"
                  />
                </FormGroup>
                <Button
                  color="primary"
                  className="pull-right"
                  onClick={() => submitPassword()}
                >
                  {showLoader ? <Spinner size="sm" /> : 'Reset password'}
                </Button>
              </Form>
            </Card>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ResetPassword;
