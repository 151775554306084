import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  addLocationsToRoute,
  addRoute,
  deleteRoutes,
  exportLocations,
  fetchRouteById,
  fetchRoutes,
  updateLocationOrder,
  updateOptimizedRouteLocations,
  updateRoute,
} from '../requests/routes.requests';
import { useDispatch } from 'react-redux';
import { TOAST } from '../../store/actions';

export const useRoutes = (query, enabled = true) =>
  useQuery({
    queryKey: ['routes', query],
    queryFn: async () => {
      const res = await fetchRoutes(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useInfiniteRoutes = (query, enabled = true) =>
  useInfiniteQuery({
    queryKey: ['infinite-routes', query],
    queryFn: async ({ pageParam }) => {
      const queries = query;
      if (pageParam) {
        queries.page = pageParam || 1;
      }

      const res = await fetchRoutes(queries);
      return res;
    },

    getPreviousPageParam: firstPage =>
      firstPage && firstPage.hasPrevPage
        ? (firstPage.page || 0) - 1
        : undefined,
    getNextPageParam: lastPage =>
      lastPage && lastPage.hasNextPage ? (lastPage.page || 0) + 1 : undefined,
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useRouteById = (routeId, query, enabled = true) =>
  useQuery({
    queryKey: ['route-by-id', routeId, query],
    queryFn: async () => {
      const res = await fetchRouteById(routeId, query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useDeleteRoutes = () =>
  useMutation({
    mutationFn: async ids => {
      const res = await deleteRoutes(ids);
      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useExportRouteLocations = () =>
  useMutation({
    mutationFn: async ids => {
      const res = await exportLocations(ids);
      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useAddLocationsToRoute = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ routeId, locationIds }) => {
      const res = await addLocationsToRoute(routeId, locationIds);
      return res;
    },
    onError: err => {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: err.error.message,
          show: true,
        },
      });
    },
  });
};
export const useAddRoute = () =>
  useMutation({
    mutationFn: async query => {
      const res = await addRoute(query);
      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useUpdateRoute = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async payload => {
      const res = await updateRoute(payload);
      console.log(res);

      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['route-by-id'] });
    },
  });
};

export const useUpdateLocationOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async payload => {
      const res = await updateLocationOrder(payload);
      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });
};

export const useUpdateOptimizedLocations = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async payload => {
      const res = await updateOptimizedRouteLocations(payload);
      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['route-location-maps-paginated'],
      });
    },
  });
};
