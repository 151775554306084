import httpServices from '../../common/service';
const url = process.env.REACT_APP_BASE_URL;

export const addLocationType = async data => {
  try {
    let uri = `${url}/location-types`;
    let resp = await httpServices.httpPostCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const updateLocationType = async (id, data) => {
  try {
    let uri = `${url}/location-types/${id}`;
    let resp = await httpServices.httpPatchCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const getLocationType = async search => {
  try {
    let uri = `${url}/location-types`;
    if (search) {
      uri += `?search=${search}`;
    }
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
export const getLocationTypeById = async id => {
  try {
    let uri = `${url}/location-types/${id}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const deleteLocationTypes = async id => {
  try {
    let uri = `${url}/location-types/${id}`;
    let resp = await httpServices.httpDeleteCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
