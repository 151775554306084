import { getLocationType } from '../store/requests/locationTypeRequest';

export const NOTIFICATION_SYSTEM_STYLE = {
  NotificationItem: {
    DefaultStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      borderRadius: '4px',
      fontSize: '14px',
    },

    success: {
      borderTop: 0,
      backgroundColor: '#45b649',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    error: {
      borderTop: 0,
      backgroundColor: 'red',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    warning: {
      borderTop: 0,
      backgroundColor: '#ffd700',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    info: {
      borderTop: 0,
      background: 'linear-gradient(to right, #6a82fb, #fc5c7d)',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },
  },

  Title: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
      paddingRight: 5,
      color: '#fff',
      display: 'inline-flex',
      fontSize: 20,
      fontWeight: 'bold',
      // left: '15px',
      // position: 'absolute',
      // top: '50%',
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      display: 'block',
      color: '#fff',
      width: '100%',
    },
  },

  Dismiss: {
    DefaultStyle: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'inherit',
      fontSize: 20,
      color: '#f2f2f2',
      position: 'relative',
      margin: 0,
      padding: 0,
      background: 'none',
      borderRadius: 0,
      opacity: 1,
      width: 20,
      height: 20,
      textAlign: 'initial',
      float: 'none',
      top: 'unset',
      right: 'unset',
      lineHeight: 'inherit',
    },
  },

  Action: {
    DefaultStyle: {
      background: '#fff',
      borderRadius: '2px',
      padding: '6px 20px',
      fontWeight: 'bold',
      margin: '10px 0 0 0',
      border: 0,
    },

    success: {
      backgroundColor: '#45b649',
      color: '#fff',
    },

    error: {
      backgroundColor: '#f85032',
      color: '#fff',
    },

    warning: {
      backgroundColor: '#ffd700',
      color: '#fff',
    },

    info: {
      backgroundColor: '#00c9ff',
      color: '#fff',
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },
};
export const getLocationTypes = async () => {
  const locationTypeResp = await getLocationType();
  if (locationTypeResp.name !== 'Error') {
    return locationTypeResp.data;
  }
};

// export const locationTypes = getLocationTypes();

export const DATE_FORMAT = 'MM-DD-YYYY hh:mm a';

export const DATE_FORMAT_SECOND = 'YYYY-MM-DD HH:mm:ss';

export const DATE_FORMAT_THIRD = 'MM-DD-YYYY';

export const DATE_FORMAT_FOURTH = 'MM-DD-YYYY HH:mm';

export const PAGE_SIZE = [10, 20, 50, 100, 'All'];

export const QUICK_SEARCH_LIST = [
  {
    text: 'Normal Search',
    key: 'search',
  },
  {
    text: 'Office Search',
    key: 'office.name',
  },
  {
    text: 'Agent Search',
    key: 'agent.displayName',
  },
  {
    text: 'Type Search',
    key: 'type',
  },
  {
    text: 'City Search',
    key: 'city',
  },
  {
    text: 'Address Search',
    key: 'address',
  },
  {
    text: 'Zip Search',
    key: 'zip',
  },
];

export const FILTER_ROUTE_LIST = [
  {
    label: 'All Routes',
    value: false,
  },
  {
    label: 'Created By Me',
    value: true,
  },
];
