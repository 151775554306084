import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

const IndeterminateCheckbox = ({ indeterminate, className = '', ...rest }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={classNames('my-[6px] cursor-pointer', className)}
      {...rest}
    />
  );
};

export default IndeterminateCheckbox;
