import Page from 'components/Page';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
// import DataTable from 'components/DataTable';
import ConfirmModal from 'components/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Button,
  Dropdown,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { TOAST } from 'store/actions';
import { BsThreeDotsVertical } from 'react-icons/bs';
import {
  // fetchAllRoutes,
  // fetchOfficeRoute,
  updateRoute,
  unassignRoutes,
} from '../../store/requests/routeRequest';
import moment from 'moment';
import AssignRouteModal from '../Modals/AssignRoutes';
import UnassignModal from '../Modals/UnassignModal';
import VisitedLocationList from '../Modals/visitedLocationList';
import Table from '../../components/shared/table/Table';
import { getActiveQuery, getDistance } from '../../utils';
import { createColumnHelper } from '@tanstack/react-table';
import {
  DATE_FORMAT,
  DATE_FORMAT_THIRD,
  FILTER_ROUTE_LIST,
} from '../../utils/constants';
import { useDeleteRoutes, useRoutes } from '../../apis/queries/routes.queries';
import { useQueryClient } from 'react-query';

const Routes = () => {
  const queryClient = useQueryClient();
  const columnHelper = createColumnHelper();
  const location = useLocation();
  const deleteRoutesHandler = useDeleteRoutes();
  const [selectedId, setSelectedId] = useState();
  const [showVisitedLocation, setShowVisitedLocation] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedOfficeId, setSelectedOfficeId] = useState();
  const [selectedAgentId, setSelectedAgentId] = useState();
  const [selectedRouteId, setSelectedRouteId] = useState();
  const [showAssignRoute, setShowAssignRoute] = useState(false);
  const [dropdownOpen, toggleDropDown] = useState(false);
  const [listType, setListType] = useState();
  const user = useSelector(state => state.auth.userData);
  const officeId = useSelector(state => state.auth.brach_office_id);
  const [showUnassign, setShowUnassign] = useState(false);
  const [assignable, setAssignable] = useState(true);
  const [notAssignable, setNotAssignable] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [quickRouteOpen, setQuickRouteOpen] = useState(false);
  const tableRef = useRef();

  const limit = getActiveQuery(location, 'routeLimit');
  const search = getActiveQuery(location, 'routeSearch');
  const page = getActiveQuery(location, 'routePage');
  const sortBy = getActiveQuery(location, 'routeSortBy');
  const sortOrder = getActiveQuery(location, 'routeSortOrder');
  const searchPath = getActiveQuery(location, 'searchPath');
  const filters = getActiveQuery(location, 'filters');

  const routesQuery = useRoutes({
    limit,
    search,
    page,
    sortBy,
    sortOrder,
    searchPath,
    filters,
    isCreatedByMe: (listType && listType.value) || undefined,
    officeId,
  });

  const handleQuickRouteOpen = () => setQuickRouteOpen(prevState => !prevState);

  // useEffect(() => {
  //   if (user && Object.keys(user).length > 0) getRoute();
  // }, [listType, user, officeId]);

  // const getRoute = async () => {
  //   // setShowSpiner(true);
  //   let createdById;
  //   if (listType === 'Created By Me') {
  //     createdById = user.id;
  //   }
  //   let resp;
  //   if (user.role === 'admin') resp = await fetchAllRoutes(createdById);
  //   else if (user.role === 'office-manager' && officeId)
  //     resp = await fetchOfficeRoute(officeId, createdById);

  //   if (resp && resp.name !== 'Error') {
  //     setSelectedItems([]);
  //     const list = resp.data;
  //     let assigned = [];
  //     list.forEach(item => {
  //       item.status = titleCase(item.status);
  //       item.createdBy = item.createdBy ? item.createdBy.username : 'NA';

  //       item.lastVisit =
  //         item.visits && item.visits.length > 0 && item.visits[0].arrival
  //           ? moment(item.visits[0].arrival).format('MM-DD-YYYY')
  //           : 'NA';

  //       item.totalLocation = item.routeLocations
  //         ? item.routeLocations.length
  //         : 0;
  //       item.distance = {
  //         html: `${getDistance(item.distance)}`,
  //         value: getDistance(item.distance),
  //       };
  //       if (item.user) {
  //         item.assigned = item.user.displayName;
  //         item.office = item.user.office ? item.user.office.name : '';
  //         assigned.push(item.id);
  //       }
  //     });
  //     setRoutes(list);
  //     setNotAssignable(assigned);
  //   }
  //   // setShowSpiner(false);
  // };

  const titleCase = str => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  };

  // const onSelectId = id => {
  //   if (typeof id === 'string') {
  //     if (selectedItems.indexOf(id) > -1) {
  //       setSelectedItems(selectedItems.filter(items => items !== id));
  //     } else {
  //       setSelectedItems([...selectedItems, id]);
  //     }
  //   } else {
  //     setSelectedItems(id);
  //   }
  // };

  const deleteRoute = id => {
    setSelectedId(id);
    setShowConfirmDelete(true);
  };

  const confirmDelete = async () => {
    if (selectedId) {
      let resp = await updateRoute(selectedId, { status: 'inactive' });
      if (resp && resp.name !== 'Error') {
        setSelectedItems([]);
        tableRef.current.resetRowSelection();
        queryClient.invalidateQueries(['routes']);
        setShowUnassign(false);
        setShowConfirmDelete(false);
        setSelectedId();
        dispatch({
          type: TOAST.SHOW,
          load: {
            type: 'success',
            title: 'Success',
            message: 'Route Deleted!',
            show: true,
          },
        });
      }
      return;
    }

    let resp = await deleteRoutesHandler.mutateAsync(
      selectedItems.map(item => item._id),
    );

    if (resp && resp.name !== 'Error') {
      setSelectedItems([]);
      tableRef.current.resetRowSelection();

      queryClient.invalidateQueries(['routes']);
      setShowUnassign(false);
      setShowConfirmDelete(false);
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'success',
          title: 'Success',
          message: 'Routes Deleted!',
          show: true,
        },
      });
    }
  };

  const confirmUnassign = async () => {
    let from = user.role === 'admin' ? 'all' : 'user';
    const data = selectedItems.map(item => item._id);

    let resp = await unassignRoutes(data, from);
    if (resp && resp.name !== 'Error') {
      setAssignable(true);
      setSelectedItems([]);
      setSelectedOfficeId('');
      setSelectedAgentId('');
      setSelectedRouteId('');
      tableRef.current.resetRowSelection();
      queryClient.invalidateQueries(['routes']);
      setShowUnassign(false);
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'success',
          title: 'Success',
          message: 'Location unassigned!',
          show: true,
        },
      });
    }
  };

  const handleAssignRoute = item => {
    setSelectedOfficeId(item.officeId);
    setSelectedAgentId(item.agent && item.agent._id);
    setSelectedRouteId(item._id);
    setShowAssignRoute(true);
  };
  const onShowVisitedLocations = id => {
    setSelectedRouteId(id);
    setShowVisitedLocation(true);
  };

  const action = [
    {
      text: ' Assign',
      key: 'Assign',
      onClick: value => handleAssignRoute(value),
    },
    {
      text: ' Details',
      key: 'Details',
      onClick: value => history.push(`/routes/${value._id}`),
    },
    {
      text: ' Delete',
      key: 'Delete',
      onClick: value => deleteRoute(value._id),
    },
    {
      text: ' Visited locations',
      key: 'visited-locations',
      onClick: value => onShowVisitedLocations(value._id),
    },
  ];

  const routesColumns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => (info.row.original.name ? info.row.original.name : 'NA'),
        header: 'Name',
      }),
      columnHelper.accessor('agent.displayName', {
        id: 'agent.displayName',
        cell: info =>
          info.row.original.agent && info.row.original.agent.displayName
            ? info.row.original.agent.displayName
            : 'NA',
        header: 'Assigned To',
      }),
      columnHelper.accessor('office.name', {
        id: 'office.name',
        cell: info =>
          info.row.original.office && info.row.original.office.name
            ? info.row.original.office.name
            : 'NA',
        header: 'Office',
      }),
      columnHelper.accessor('totalLocation', {
        id: 'totalLocation',
        cell: info =>
          info.row.original.totalLocation ? info.row.original.totalLocation : 0,
        header: 'Total Locations',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('distance', {
        id: 'distance',
        cell: info =>
          info.row.original.distance
            ? getDistance(info.row.original.distance)
            : 0,
        header: 'Distance(mi)',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('createdBy.email', {
        id: 'createdBy.email',
        cell: info =>
          info.row.original.createdBy && info.row.original.createdBy.email
            ? info.row.original.createdBy.email
            : 'NA',
        header: 'Added By',
        enableColumnFilter: false,
      }),
      columnHelper.accessor('lastVisited', {
        id: 'lastVisited',
        cell: info =>
          info.row.original.lastVisited
            ? moment(info.row.original.lastVisited).format(DATE_FORMAT_THIRD)
            : 0,
        header: 'Last Visit',
      }),
      columnHelper.accessor('status', {
        id: 'status',
        cell: info =>
          info.row.original.status ? info.row.original.status : 'NA',
        header: 'Status',
      }),
      columnHelper.accessor('id', {
        id: 'id',
        cell: info => (
          <UncontrolledDropdown>
            <DropdownToggle color="none">
              <BsThreeDotsVertical size={20} />
            </DropdownToggle>

            <DropdownMenu>
              {action.map(item => (
                <DropdownItem
                  key={item.key}
                  onClick={() =>
                    info.row.original._id
                      ? item.onClick(info.row.original)
                      : null
                  }
                >
                  {item.text}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
        header: 'Actions',
        enableColumnFilter: false,
        enableSorting: false,
      }),
    ],
    [action, columnHelper],
  );

  useEffect(() => {
    const tempAssign =
      (selectedItems.length &&
        selectedItems
          .map(item => {
            if (item.office || item.agent) {
              return true;
            } else {
              return false;
            }
          })
          .every(item => item !== true)) ||
      false;

    setAssignable(tempAssign);
  }, [selectedItems]);

  const onCloseAssignRouteModal = () => {
    setSelectedOfficeId('');
    setSelectedAgentId('');
    setSelectedRouteId();
    setShowAssignRoute(false);
    setSelectedItems([]);
    tableRef.current.resetRowSelection();
    queryClient.invalidateQueries(['routes']);
  };

  return (
    <Page className="DashboardPage" title="Routes">
      <div className="header-button-sec">
        <Button
          color="primary"
          className="pull-right mb-4 ml-3"
          onClick={() => history.push('/create-route')}
        >
          Create Route
        </Button>
        <Button
          color="primary"
          className="pull-right mb-4 ml-3"
          onClick={() => {
            setShowAssignRoute(true);
          }}
          disabled={!selectedItems.length || !assignable}
        >
          Assign
        </Button>
        <Button
          color="danger"
          className="pull-right mb-4 ml-3"
          onClick={() => setShowUnassign(true)}
          disabled={!selectedItems.length || assignable}
        >
          Unassign Routes
        </Button>
        <Button
          color="danger"
          className="pull-right mb-4 ml-3"
          onClick={() => setShowConfirmDelete(true)}
          disabled={!selectedItems.length}
        >
          Delete
        </Button>

        <Dropdown
          isOpen={quickRouteOpen}
          toggle={handleQuickRouteOpen}
          style={{ width: 'fit-content' }}
        >
          <DropdownToggle caret>
            {(listType && listType.label) || 'All Routes'}
          </DropdownToggle>

          <DropdownMenu>
            {FILTER_ROUTE_LIST.map(item => (
              <DropdownItem key={item.label} onClick={() => setListType(item)}>
                {item.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>

      <Card className="page-card box-shadow">
        <Table
          columns={routesColumns}
          data={
            !routesQuery.isLoading && routesQuery.isFetching
              ? []
              : routesQuery.data && routesQuery.data.data
              ? routesQuery.data.data
              : []
          }
          placeholder="Search normal"
          searchKey="routeSearch"
          limitKey="routeLimit"
          pageKey="routePage"
          sortByKey="routeSortBy"
          sortOrderKey="routeSortOrder"
          totalItems={
            routesQuery.data && routesQuery.data.totalDocs
              ? routesQuery.data.totalDocs
              : 0
          }
          totalPages={
            routesQuery.data && routesQuery.data.totalPage
              ? routesQuery.data.totalPage
              : 0
          }
          isLoading={routesQuery.isLoading || routesQuery.isFetching}
          allowRowsSelect
          setRowItems={setSelectedItems}
          ref={tableRef}
          selectedItems={selectedItems}
        />
      </Card>

      <ConfirmModal
        show={showConfirmDelete}
        onClose={() => {
          setShowConfirmDelete(false);
          setSelectedId();
        }}
        title={selectedId ? 'Delete Route' : 'Delete Routes'}
        body="Are you sure?"
        actions={[
          <Button onClick={() => confirmDelete()} color="danger">
            Delete
          </Button>,
          <Button
            color="primary"
            onClick={() => {
              setShowConfirmDelete(false);
              setSelectedId();
            }}
          >
            Cancel
          </Button>,
        ]}
      />
      <UnassignModal
        show={showUnassign}
        onClose={() => {
          setShowUnassign(false);
        }}
        title="Unassign routes"
        body="Are you sure?"
        showRouteUnassign={true}
        onSubmit={value => confirmUnassign(value)}
      />
      <AssignRouteModal
        show={showAssignRoute}
        onClose={onCloseAssignRouteModal}
        onDone={onCloseAssignRouteModal}
        selectedOfficeId={selectedOfficeId}
        selectedAgentId={selectedAgentId}
        selectedRouteId={selectedRouteId}
        selectedItems={selectedItems}
      />

      <VisitedLocationList
        show={showVisitedLocation}
        onClose={() => {
          setSelectedId(null);
          setShowVisitedLocation(false);
        }}
        selectedRouteId={selectedRouteId}
      />
    </Page>
  );
};
export default Routes;
