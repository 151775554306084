import { useHistory, useLocation } from 'react-router-dom';

// const SORT_BY = 'sortBy';
// const SORT_ORDER = 'sortOrder';
const ASC = 'asc';
const DESC = 'desc';

const useSort = (
  sortByKey = 'sortBy',
  sortOrderKey = 'sortOrder',
  defaultSortBy,
  defaultSortOrder,
) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const handleSortByColumn = colId => {
    const currentSortBy = searchParams.get(sortByKey);
    const currentSortOrder = searchParams.get(sortOrderKey);
    let newSortOrder = defaultSortOrder || ASC;

    if (currentSortBy === colId) {
      newSortOrder = currentSortOrder === ASC ? DESC : ASC;
    }

    searchParams.set(sortByKey, colId);
    searchParams.set(sortOrderKey, newSortOrder);

    const newQueryString = searchParams.toString();
    history.replace({
      search: newQueryString,
    });
  };

  return {
    handleSortByColumn,
    currentSortBy: searchParams.get(sortByKey) || defaultSortBy || '',
    currentSortOrder: searchParams.get(sortOrderKey) || defaultSortOrder || ASC,
  };
};

export default useSort;
