import httpServices from '../../common/service';
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_KEY;
const GRAPH_HOPPER_KEY = process.env.REACT_APP_GRAPH_HOPPER_KEY;

export const getDirection = async (origin, destination) => {
  try {
    let uri = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&mode=riding&key=${GOOGLE_API_KEY}`;

    let directionResp = await httpServices.httpGetCall(uri);
    return directionResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const getUnOptimizedRoute = async points => {
  try {
    let data = {
      points: points,
      snap_preventions: ['motorway', 'ferry', 'tunnel'],
      vehicle: 'bike',
      locale: 'en',
      elevation: false,
      optimize: 'false',
      instructions: true,
      calc_points: true,
      points_encoded: false,
      'ch.disable': false,
      heading_penalty: 120,
      pass_through: false,
    };
    let uri = `https://graphhopper.com/api/1/route?key=${GRAPH_HOPPER_KEY}`;

    // let directionResp = await axios.post(uri, data);

    const response = await fetch(uri, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    return response.json();

    // return directionResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const getOptimizedRoutes = async points => {
  try {
    let data = {
      points: points,
      snap_preventions: ['motorway', 'ferry', 'tunnel'],
      vehicle: 'bike',
      locale: 'en',
      elevation: false,
      optimize: 'true',
      instructions: true,
      calc_points: true,
      points_encoded: false,
      'ch.disable': false,
      weighting: 'fastest',
      heading_penalty: 120,
      pass_through: false,
    };
    let uri = `https://graphhopper.com/api/1/route?key=${GRAPH_HOPPER_KEY}`;

    // let directionResp = await axios.post(uri, data);

    const response = await fetch(uri, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    return response.json();

    // return directionResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const geoCodeLocation = async location => {
  try {
    let uri = `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${GOOGLE_API_KEY}`;
    let geocodeResp = await httpServices.httpGetCall(uri);
    return geocodeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const reverseGeoCodeLocation = async location => {
  try {
    let uri = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location}&key=${GOOGLE_API_KEY}`;
    let geocodeResp = await httpServices.httpGetCall(uri);
    return geocodeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
