import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import {
  createLocationType,
  fetchLocationTypes,
} from '../requests/locationTypes.requests';
import { deleteLocationTypes } from '../../store/requests/locationTypeRequest';

export const useInfiniteLocationTypes = (query, enabled = true) =>
  useInfiniteQuery({
    queryKey: ['infinite-location-types', query],
    queryFn: async ({ pageParam }) => {
      const queries = query;
      if (pageParam) {
        queries.page = pageParam || 1;
      }

      const res = await fetchLocationTypes(queries);
      return res;
    },
    getNextPageParam: lastPage =>
      lastPage && lastPage.hasNextPage ? (lastPage.page || 0) + 1 : undefined,
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useCreateLocationType = () =>
  useMutation({
    mutationFn: async data => {
      const res = await createLocationType(data);
      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useLocationTypes = (query, enabled = true) =>
  useQuery({
    queryKey: ['locationTypes', query],
    queryFn: async () => {
      const res = await fetchLocationTypes(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useDeleteLocationType = () =>
  useMutation({
    mutationFn: async id => {
      const res = await deleteLocationTypes(id);
      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });
