import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import {
  exportAgents,
  exportLocations,
  exportRoutes,
  exportSubOffices,
  fetchAgentsByOfficeId,
  fetchLocationsByOfficeId,
  fetchOfficeById,
  fetchReportsByOffices,
  fetchRoutesByOfficeId,
} from '../requests/offices.requests';
import {
  fetchBranchOffices,
  fetchBranchSubOffices,
  fetchOffices,
} from '../../store/requests/officeRequests';

export const useReportsByOffices = (query, enabled = true) =>
  useQuery({
    queryKey: ['reports-by-offices', query],
    queryFn: async () => {
      const res = await fetchReportsByOffices(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useInfiniteReportsByOffices = (query, enabled = true) =>
  useInfiniteQuery({
    queryKey: ['infinite-reports-by-offices', query],
    queryFn: async ({ pageParam }) => {
      const queries = query;
      if (pageParam) {
        queries.page = pageParam || 1;
      }

      const res = await fetchReportsByOffices(queries);
      return res;
    },

    getPreviousPageParam: firstPage =>
      firstPage && firstPage.hasPrevPage
        ? (firstPage.page || 0) - 1
        : undefined,
    getNextPageParam: lastPage =>
      lastPage && lastPage.hasNextPage ? (lastPage.page || 0) + 1 : undefined,
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useOfficeById = (officeId, query, enabled = true) =>
  useQuery({
    queryKey: ['reports-by-office-id', officeId, query],
    queryFn: async () => {
      const res = await fetchOfficeById(officeId, query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useLocationsByOfficeId = (officeId, query, enabled = true) =>
  useQuery({
    queryKey: ['locations-by-office-id', officeId, query],
    queryFn: async () => {
      const res = await fetchLocationsByOfficeId(officeId, query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useRoutesByOfficeId = (officeId, query, enabled = true) =>
  useQuery({
    queryKey: ['routes-by-office-id', officeId, query],
    queryFn: async () => {
      const res = await fetchRoutesByOfficeId(officeId, query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useAgentsByOfficeId = (officeId, query, enabled = true) =>
  useQuery({
    queryKey: ['agents-by-office-id', officeId, query],
    queryFn: async () => {
      const res = await fetchAgentsByOfficeId(officeId, query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useOffices = (query, enabled = true) =>
  useQuery({
    queryKey: ['offices', query],
    queryFn: async () => {
      const res = await fetchOffices(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useInfiniteOffices = (query, enabled = true) =>
  useInfiniteQuery({
    queryKey: ['infinite-location-types', query],
    queryFn: async ({ pageParam }) => {
      const queries = query;
      if (pageParam) {
        queries.page = pageParam || 1;
      }

      const res = await fetchOffices(queries);
      return res;
    },
    getNextPageParam: lastPage =>
      lastPage && lastPage.hasNextPage ? (lastPage.page || 0) + 1 : undefined,
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useExportLocations = () =>
  useMutation({
    mutationFn: async id => {
      const res = await exportLocations(id);
      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useExportRoutes = () =>
  useMutation({
    mutationFn: async id => {
      const res = await exportRoutes(id);
      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useExportAgents = () =>
  useMutation({
    mutationFn: async id => {
      const res = await exportAgents(id);
      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useExportSubOffices = () =>
  useMutation({
    mutationFn: async id => {
      const res = await exportSubOffices(id);
      return res;
    },
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });
