import moment from 'moment';
import httpServices from '../../common/service';
import http from '../../apis/http';
import urlcat from 'urlcat';
const url = process.env.REACT_APP_BASE_URL;
export const addOffice = async data => {
  let uri = `${url}/offices`;
  let office = await httpServices.httpPostCall(`${uri}`, data);
  return office;
};

// export const fetchOffices = async query => {
//   let uri = `${url}/offices/report`;
//   // let filter = {
//   //   where: {
//   //     status: 'active',
//   //   },
//   // };
//   // uri += `?filter=${JSON.stringify(filter)}`;
//   let offices = await httpServices.httpGetCall(`${uri}`);
//   return offices;
// };

export const fetchOffices = query => http.get(urlcat('/offices/report', query));

export const fetchOfficeDetails = async id => {
  try {
    let uri = `${url}/offices/${id}`;
    let filter = {
      include: [{ relation: 'manager' }],
    };
    uri += `?filter=${JSON.stringify(filter)}`;

    let offices = await httpServices.httpGetCall(`${uri}`);
    return offices;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const updateOffice = async (id, data) => {
  try {
    let where = {
      id: id,
    };
    let uri = `${url}/offices?where=${JSON.stringify(where)}`;
    let officeResp = await httpServices.httpPatchCall(uri, data);
    return officeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const addBranchOffice = async data => {
  try {
    let uri = `${url}/branch-offices`;
    let officeResp = await httpServices.httpPostCall(uri, data);
    return officeResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchBranchOffices = async (officeId, limit, offset) => {
  let uri = `${url}/branch-offices`;
  let filter = {
    where: {
      parentId: officeId,
    },
    include: [
      {
        relation: 'branch',
      },
      {
        relation: 'parent',
      },
    ],
  };
  if (limit) {
    filter.limit = limit;
  }
  if (offset) {
    filter.skip = offset;
  }
  uri += `?filter=${JSON.stringify(filter)}`;
  let offices = await httpServices.httpGetCall(`${uri}`);
  return offices;
};

export const fetchBranchOfficesCount = async (officeId, limit, offset) => {
  let uri = `${url}/branch-offices/count`;
  let where = {
    parentId: officeId,
  };

  uri += `?where=${JSON.stringify(where)}`;
  let offices = await httpServices.httpGetCall(`${uri}`);
  return offices;
};

export const fetchTopOffices = async (officeId, dateRange) => {
  try {
    let uri = `${url}/users/top-office`;
    if (officeId) uri += `?officeId=${officeId}`;
    if (!officeId && dateRange)
      uri += `?from=${moment(dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss',
      )}&to=${moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss')}`;
    else if (officeId && dateRange)
      uri += `&from=${moment(dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss',
      )}&to=${moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss')}`;

    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
export const assignParentOffice = async (officeId, parentOfficeId) => {
  try {
    const uri = `${url}/offices/${officeId}/assign-parent/${parentOfficeId}`;
    const resp = await httpServices.httpPostCall(uri);
    return resp;
  } catch (err) {}
};
