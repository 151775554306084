import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

// class AuthForm extends React.Component {
const AuthForm = props => {
  const {
    showLogo,
    usernameLabel,
    usernameInputProps,
    passwordLabel,
    passwordInputProps,
    onLogoClick,
    onSubmit,
  } = props;
  const handleSubmit = event => {
    event.preventDefault();
    onSubmit();
  };
  return (
    <div>
      {showLogo && (
        <div className="text-center pb-4">
          <img
            src={logo200Image}
            className="rounded"
            style={{ width: 60, height: 60, cursor: 'pointer' }}
            alt="logo"
            onClick={onLogoClick}
          />
        </div>
      )}
      <Form onSubmit={handleSubmit} className="pl-5 pr-5">
        <FormGroup>
          <Label for={usernameLabel} className="font-weight-bold">
            {usernameLabel}
          </Label>
          <Input {...usernameInputProps} />
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel} className="font-weight-bold">
            {passwordLabel}
          </Label>
          <Input {...passwordInputProps} />
        </FormGroup>

        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={handleSubmit}
          color="primary"
        >
          {'Login'}
        </Button>
        <div className="text-center mt-4 ">
          <a
            href="/forgot-password"
            className="font-weight-bold color-black font-s-12"
          >
            Forgot Password
          </a>
        </div>
      </Form>
    </div>
  );
};

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default AuthForm;
