import httpServices from '../../common/service';
import { getUrl } from './checkRole';
const url = process.env.REACT_APP_BASE_URL;

export const addLocation = async data => {
  try {
    let uri = `${url}/locations`;

    let resp = await httpServices.httpPostCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchLocations = async (officeId, limit, offset) => {
  try {
    let uri = `${url}/${getUrl('locations')}`;
    let filter = {
      where: {
        status: { neq: 'inactive' },
      },
      include: [
        {
          relation: 'userLocations',
          scope: {
            include: [
              {
                relation: 'user',
              },
            ],
          },
        },
        {
          relation: 'createdBy',
        },
        {
          relation: 'contacts',
        },
        {
          relation: 'visits',
          scope: {
            order: ['arrival', 'DESC'],
          },
        },
        {
          relation: 'routeLocations',
          scope: {
            where: {
              isVisited: true,
            },
            order: ['arrivalTime', 'DESC'],
          },
        },
      ],
    };
    if (!officeId) {
      filter.include.push({
        relation: 'officeLocations',
        scope: {
          include: [
            {
              relation: 'office',
            },
          ],
        },
      });
    }
    if (limit) {
      filter.limit = limit;
    }
    if (offset) {
      filter.skip = offset;
    }
    uri += `?filter=${JSON.stringify(filter)}`;

    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchLocationDetails = async id => {
  try {
    let uri = `${url}/locations/${id}`;
    let filter = {
      include: [
        { relation: 'chain' },
        { relation: 'type' },
        {
          relation: 'createdBy',
          scope: {
            include: [{ relation: 'office' }],
          },
        },
        {
          relation: 'contacts',
          scope: {
            where: {
              status: { neq: 'inactive' },
            },
          },
        },
        {
          relation: 'visits',
          scope: {
            include: [
              {
                relation: 'user',
                scope: {
                  include: [{ relation: 'office' }],
                },
              },
              {
                relation: 'contacts',
              },
            ],
          },
        },
      ],
    };
    uri += `?filter=${JSON.stringify(filter)}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const updateLocation = async (id, data) => {
  try {
    let uri = `${url}/locations/${id}`;

    let resp = await httpServices.httpPatchCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const getOfficeLocation = async (id, limit, offset) => {
  try {
    let uri = `${url}/offices/${id}/locations`;
    let filter = {
      include: [
        { relation: 'chain' },
        {
          relation: 'contacts',
          scope: {
            where: {
              status: { neq: 'inactive' },
            },
          },
        },
        {
          relation: 'visits',
          scope: {
            order: ['arrival DESC'],
            include: [
              {
                relation: 'contact',
              },
            ],
          },
        },
      ],
    };
    if (limit) {
      filter.limit = limit;
    }
    if (offset) {
      filter.skip = offset;
    }
    uri += `?filter=${JSON.stringify(filter)}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
export const getOfficeLocationCount = async id => {
  try {
    let uri = `${url}/offices/${id}/locations/count`;

    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchAssignableLocations = async () => {
  try {
    let uri = `${url}/locations`;
    let filter = {
      where: {
        status: 'active',
        officeId: { neq: null },
      },
    };
    uri += `?filter=${JSON.stringify(filter)}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const assignLocationToOffice = async (id, data) => {
  try {
    let uri = `${url}/users/assign-office-locations/${id}`;
    let resp = await httpServices.httpPostCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchAssignedLocationOffice = async id => {
  try {
    let uri = `${url}/office-locations`;
    let filter = {
      where: { locationId: id },
      include: [{ relation: 'office' }],
    };
    uri += `/?filter=${JSON.stringify(filter)}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchAssignedLocationUser = async (id, officeId) => {
  try {
    let uri = `${url}/user-locations`;
    let filter = {
      where: { locationId: id },
      include: [{ relation: 'user' }],
    };
    uri += `?filter=${JSON.stringify(filter)}`;
    let resp = await httpServices.httpGetCall(uri);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const uploadCsv = async data => {
  try {
    let uri = `${url}/locations/csv`;

    let resp = await httpServices.httpPostCall(uri, data);
    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const unAssignLocation = async (data, from) => {
  try {
    let uri = `${url}/locations/unassign-locations?locations=${JSON.stringify({
      locations: data,
    })}&from=${JSON.stringify(from)}`;

    let resp = await httpServices.httpPostCall(uri, null);

    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchUnassignedOfficeLocation = async officeId => {
  try {
    let uri = `${url}/offices/${officeId}/unassigned-locations`;

    let resp = await httpServices.httpGetCall(uri, null);

    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const fetchLocationContacts = async locationId => {
  try {
    let uri = `${url}/locations/${locationId}/contacts`;

    let resp = await httpServices.httpGetCall(uri, null);

    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const deleteMultiLocation = async locationIds => {
  try {
    let uri = `${url}/locations/delete-locations?locations=${JSON.stringify({
      locations: locationIds,
    })}&from=${JSON.stringify({})}`;

    let resp = await httpServices.httpPostCall(uri);

    return resp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
