import { useInfiniteQuery, useQuery } from 'react-query';
import { fetchParentOffices, fetchSubOffices } from '../requests/branchOffices.requests';

export const useSubOffices = (query, enabled = true) =>
  useQuery({
    queryKey: ['sub-offices', query],
    queryFn: async () => {
      const res = await fetchSubOffices(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useInfiniteSubOffices = (query, enabled) =>
  useInfiniteQuery({
    queryKey: ['infinite-sub-offices', query, enabled],
    queryFn: async ({ pageParam }) => {
      const queries = query;
      if (pageParam) {
        queries.page = pageParam || 1;
      }

      const res = await fetchSubOffices(queries);
      return res;
    },
    getNextPageParam: lastPage =>
      lastPage && lastPage.hasNextPage ? (lastPage.page || 0) + 1 : undefined,
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });



  export const useParentOffice = (id, enabled = true) =>
    useQuery({
      queryKey: ['sub-offices', id],
      queryFn: async () => {
        const res = await fetchParentOffices(id);
        return res;
      },
      enabled,
      onError: err => {
        console.log(err.message ? err.message : '');
      },
    });
