import { TOAST } from '../actions';

const initialState = {
  toastData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOAST.SHOW:
      return { ...state, toastData: action.load };
    default:
      return state;
  }
};
