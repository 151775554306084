import { useInfiniteQuery, useQuery } from 'react-query';
import {
  fetchActiveAgents,
  fetchConfirmedVisitedLocations,
  fetchReportStats,
  fetchReports,
  fetchRouteTemplatesByUserId,
  fetchTopAgents,
  fetchTopOffices,
  fetchUnConfirmedVisitedLocations,
  fetchUserById,
  fetchUsers,
  fetchVisitNotes,
  fetchVisitedLocations,
} from '../requests/users.requests';

export const useActiveAgents = (query, enabled = true) =>
  useQuery({
    queryKey: ['active-agents', query],
    queryFn: async () => {
      const res = await fetchActiveAgents(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useTopOffices = (query, enabled = true) =>
  useQuery({
    queryKey: ['top-office', query],
    queryFn: async () => {
      const res = await fetchTopOffices(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useTopAgents = (query, enabled = true) =>
  useQuery({
    queryKey: ['top-agents', query],
    queryFn: async () => {
      const res = await fetchTopAgents(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useUsers = (query, enabled = true) =>
  useQuery({
    queryKey: ['users', query],
    queryFn: async () => {
      const res = await fetchUsers(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useInfiniteUsers = (query, enabled = true) =>
  useInfiniteQuery({
    queryKey: ['infinite-users', query],
    queryFn: async ({ pageParam }) => {
      const queries = query;
      if (pageParam) {
        queries.page = pageParam || 1;
      }

      const res = await fetchUsers(queries);
      return res;
    },

    getPreviousPageParam: firstPage =>
      firstPage && firstPage.hasPrevPage
        ? (firstPage.page || 0) - 1
        : undefined,
    getNextPageParam: lastPage =>
      lastPage && lastPage.hasNextPage ? (lastPage.page || 0) + 1 : undefined,
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useUserById = (userId, query, enabled = true) =>
  useQuery({
    queryKey: ['user-by-id', userId, query],
    queryFn: async () => {
      const res = await fetchUserById(userId, query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useVisitedLocations = (id, query, enabled = true) =>
  useQuery({
    queryKey: ['visited-locations', id, query],
    queryFn: async () => {
      const res = await fetchVisitedLocations(id, query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useReports = (query, enabled = true) =>
  useQuery({
    queryKey: ['reports', query],
    queryFn: async () => {
      const res = await fetchReports(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useReportsStats = (query, enabled = true) =>
  useQuery({
    queryKey: ['reportsStats', query],
    queryFn: async () => {
      const res = await fetchReportStats(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useInfiniteRouteTemplateByUserId = (
  userId,
  query,
  enabled = true,
) =>
  useInfiniteQuery({
    queryKey: ['infinite-route-template-by-user-id', userId, query],
    queryFn: async ({ pageParam }) => {
      const queries = query;
      if (pageParam) {
        queries.page = pageParam || 1;
      }

      const res = await fetchRouteTemplatesByUserId(userId, queries);
      return res;
    },
    getPreviousPageParam: firstPage =>
      firstPage && firstPage.hasPrevPage
        ? (firstPage.page || 0) - 1
        : undefined,
    getNextPageParam: lastPage =>
      lastPage && lastPage.hasNextPage ? (lastPage.page || 0) + 1 : undefined,
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useConfirmedVisitedLocationsReports = (query, enabled = true) =>
  useQuery({
    queryKey: ['confirmedVisitedLocations', query],
    queryFn: async () => {
      const res = await fetchConfirmedVisitedLocations(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useUnConfirmedVisitedLocationsReports = (query, enabled = true) =>
  useQuery({
    queryKey: ['unConfirmedVisitedLocations', query],
    queryFn: async () => {
      const res = await fetchUnConfirmedVisitedLocations(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });

export const useVisitNotes = (query, enabled = true) =>
  useQuery({
    queryKey: ['visit-notes', query],
    queryFn: async () => {
      const res = await fetchVisitNotes(query);
      return res;
    },
    enabled,
    onError: err => {
      console.log(err.message ? err.message : '');
    },
  });
