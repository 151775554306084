import React from 'react';
import Select from 'react-dropdown-select';

const MultiselectDropDown = ({
  options = [],
  value = [],
  onSelect,
  multi = false,
  placeholder,
  labelField,
  valueField,
  searchable = true,
  disabled = false,
  itemRenderer = null,
  separator = false,
  create = false,
  onCreateNew = null,
}) => {
  return (
    <Select
      options={options} // Options to display in the dropdown
      values={value} // Preselected value to persist in dropdown
      onChange={onSelect} // Function will trigger on select event
      //   onRemove={this.onRemove} // Function will trigger on remove event
      displayValue="name" // Property name to display in the dropdown options
      searchBy={labelField}
      multi={multi}
      placeholder={placeholder}
      labelField={labelField}
      valueField={valueField}
      searchable={searchable}
      disabled={disabled}
      itemRenderer={itemRenderer}
      create={create}
      onCreateNew={onCreateNew}
    />
  );
};

export default MultiselectDropDown;
