import React, { useState } from 'react';
import {
  Col,
  Row,
  Spinner,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
} from 'reactstrap';
import validator from 'validator';

import { useDispatch, useSelector } from 'react-redux';
// import { userLoginAction } from '../store/actions/authAction';
import { TOAST } from 'store/actions';
import logo200Image from 'assets/img/logo/ExpiTrack-black.png';
import Typography from 'components/Typography';

// class AuthPage extends React.Component {
const Contact = () => {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    subject: '',
  });
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);

  const handleOnChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const initial_loading = useSelector(state => state.auth.initial_load);

  const validateForm = () => {
    if (!validator.isEmail(formData.email)) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: "Email isn't valid",
          show: true,
        },
      });

      return false;
    } else if (!formData.name) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: 'Name is required',
          show: true,
        },
      });

      return false;
    } else if (!formData.subject) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: 'Subject is required',
          show: true,
        },
      });

      return false;
    } else {
      return true;
    }
  };

  const submitContact = async () => {
    let isValid = validateForm();
    if (isValid) {
      setShowLoader(true);

      setTimeout(() => {
        dispatch({
          type: TOAST.SHOW,
          load: {
            type: 'success',
            title: 'Success',
            message: 'Your enquiry is submitted',
            show: true,
          },
        });

        setShowLoader(false);
      }, 300);
    }
  };

  return (
    <div className="pt-4 mt-4">
      <div className="text-center pb-4">
        <img
          src={logo200Image}
          className="rounded"
          style={{ width: 150, height: 30, cursor: 'pointer' }}
          alt="logo"
          // onClick={onLogoClick}
        />
      </div>

      <Row className="mt-5">
        <Col xs={12}>
          {initial_loading ? (
            <Spinner
              size="lg"
              animation="border"
              className="page-spinner"
              variant="info"
            />
          ) : (
            <Row>
              <Col
                xs={6}
                className="border-right d-flex justify-content-center"
              >
                <div className="contact-form">
                  <Typography
                    type="h5"
                    className="font-weight-bold text-center"
                  >
                    Contact us
                  </Typography>
                  <Form>
                    <FormGroup>
                      <Label for={'email'} className="font-weight-bold">
                        Email
                      </Label>
                      <Input
                        placeholder="email"
                        onChange={e => handleOnChange('email', e.target.value)}
                        type="email"
                        name="email"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for={'name'} className="font-weight-bold">
                        Name
                      </Label>
                      <Input
                        placeholder="Name"
                        onChange={e => handleOnChange('name', e.target.value)}
                        name="name"
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for={'subject'} className="font-weight-bold">
                        Subject
                      </Label>
                      <Input
                        placeholder="Subject"
                        onChange={e =>
                          handleOnChange('subject', e.target.value)
                        }
                        name="subject"
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for={'enquiry'} className="font-weight-bold">
                        Enquiry
                      </Label>

                      <Input
                        placeholder="Enquiry"
                        onChange={e =>
                          handleOnChange('enquiry', e.target.value)
                        }
                        name="enquiry"
                        type="textarea"
                      />
                    </FormGroup>
                    <Button
                      color="primary"
                      className="pull-right"
                      onClick={() => submitContact()}
                    >
                      {showLoader ? <Spinner size="sm" /> : 'Submit enquiry'}
                    </Button>
                  </Form>
                </div>
              </Col>

              <Col
                xs={6}
                className="border-right d-flex justify-content-center"
              >
                <div className="contact-form">
                  <Row>
                    <Col>
                      <Typography
                        type="h5"
                        className="font-weight-bold text-center"
                      >
                        Contact information
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Typography className="font-weight-bold d-inline">
                        Name :
                      </Typography>
                      <Typography className="d-inline ml-2">
                        Scott Vickers
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Typography className="font-weight-bold d-inline">
                        Email :
                      </Typography>
                      <Typography className="d-inline ml-2">
                        scott@expitrans.com
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Typography className="font-weight-bold d-inline">
                        Phone :
                      </Typography>
                      <Typography className="d-inline ml-2">
                        888-270-3642
                      </Typography>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
