import { STATE_LOGIN } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React, { useEffect } from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/globals.scss';
import './styles/reduction.scss';
import { Provider } from 'react-redux';
import store from './store/reducers';
import Toast from 'components/Toast';
import { checkLogin } from './store/actions/authAction';
import Routes from './pages/Routes/Routes';
import RouteDetails from './pages/Routes/RouteDetails';
import CreateRoute from './pages/Routes/CreateRoute';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import Contact from 'pages/Contact';
import VerifyCode from 'pages/ForgotPassword/VerifyOtp';
import ResetPassword from 'pages/ForgotPassword/ResetPassword';
import { QueryClient, QueryClientProvider } from 'react-query';

const DashboardPage = React.lazy(() => import('pages/DashBoard/DashboardPage'));
const Offices = React.lazy(() => import('pages/Offices/List'));
const AddEditOffice = React.lazy(() => import('pages/Offices/AddEditOffice'));
const OfficeDetails = React.lazy(() => import('pages/Offices/Details'));
const Locations = React.lazy(() => import('pages/Locations/List'));
const LocationDetails = React.lazy(() => import('pages/Locations/Details'));
const AddEditLocation = React.lazy(() =>
  import('pages/Locations/AddEditLocation'),
);
const AddEditContact = React.lazy(() => import('pages/Contact/AddEditContact'));
// const AgentsList = React.lazy(() => import('pages/Agents/List'));
const Agent = React.lazy(() => import('pages/Agents/Index'));
const AddEditAgent = React.lazy(() => import('pages/Agents/AddEditAgent'));
const AgentDetails = React.lazy(() => import('pages/Agents/Details'));
const AddEditLocationType = React.lazy(() =>
  import('pages/LocationType/AddEditLocationType'),
);
const LocationType = React.lazy(() => import('pages/LocationType/List'));

const OnGoingRoute = React.lazy(() => import('pages/DashBoard/OnGoingRoute'));
const AgentMap = React.lazy(() => import('pages/DashBoard/AgentMap'));

const Reports = React.lazy(() => import('pages/Report/Report'));
const Visits = React.lazy(() => import('pages/Visits/Visits'));

const App = props => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    if (localStorage.getItem('auth-token')) {
      store.dispatch(checkLogin());
    } else {
      if (
        ![
          '/',
          '/forgot-password',
          '/verify',
          '/reset-password',
          '/contact',
        ].includes(window.location.pathname)
      ) {
        window.location.href = '/';
      }
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Toast />
        <BrowserRouter
        // basename={getBasename()}
        >
          <GAListener>
            <Switch>
              <LayoutRoute
                exact
                path="/"
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path="/contact"
                layout={Contact}
                component={props => <ForgotPassword {...props} />}
              />
              <LayoutRoute
                exact
                path="/forgot-password"
                layout={EmptyLayout}
                component={props => <ForgotPassword {...props} />}
              />
              <LayoutRoute
                exact
                path="/verify"
                layout={EmptyLayout}
                component={props => <VerifyCode {...props} />}
              />
              <LayoutRoute
                exact
                path="/reset-password"
                layout={EmptyLayout}
                component={props => <ResetPassword {...props} />}
              />

              <MainLayout breakpoint={props.breakpoint}>
                <React.Suspense fallback={<PageSpinner />}>
                  <Route exact path="/dashboard" component={DashboardPage} />
                  <Route
                    exact
                    path="/dashboard/agent-map"
                    component={AgentMap}
                  />
                  <Route exact path="/offices" component={Offices} />
                  <Route exact path="/add-office" component={AddEditOffice} />
                  <Route
                    exact
                    path="/office-details/:id"
                    component={OfficeDetails}
                  />
                  <Route exact path="/locations" component={Locations} />
                  <Route
                    exact
                    path="/add-edit-location/:id?/:route?/:order?"
                    component={AddEditLocation}
                  />
                  <Route
                    exact
                    path="/location-details/:id"
                    component={LocationDetails}
                  />
                  <Route
                    exact
                    path="/add-edit-contact/:type/:id"
                    component={AddEditContact}
                  />
                  <Route exact path="/routes" component={Routes} />
                  <Route exact path="/reports" component={Reports} />
                  <Route exact path="/visits" component={Visits} />
                  <Route exact path="/Agents" component={Agent} />
                  <Route
                    exact
                    path="/location-types"
                    component={LocationType}
                  />

                  <Route
                    exact
                    path="/add-edit-location-type/:id?"
                    component={AddEditLocationType}
                  />
                  <Route
                    exact
                    path="/add/:type/:officeId?"
                    component={AddEditAgent}
                  />
                  <Route
                    exact
                    path="/agent/:id/:track?"
                    component={AgentDetails}
                  />
                  <Route exact path="/routes/:id" component={RouteDetails} />
                  <Route exact path="/create-route" component={CreateRoute} />
                  <Route
                    exact
                    path="/on-going-route/:id/:track?"
                    component={OnGoingRoute}
                  />
                </React.Suspense>
              </MainLayout>
              <Redirect to="/" />
            </Switch>
          </GAListener>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
};

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
