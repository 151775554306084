import http from '../http';
import urlcat from 'urlcat';

export const fetchRoutes = query => http.get(urlcat('/routes', query));

export const fetchRouteById = (routeId, query) =>
  http.get(urlcat(`/routes/${routeId}`, query));

export const deleteRoutes = ids => http.delete('/routes/delete-many', ids);

export const exportLocations = id =>
  http.get(`/route-location-maps/${id}/export`);

export const addLocationsToRoute = (routeId, locationIds) =>
  http.post(`/routes/${routeId}/add-locations`, { locationIds });

export const addRoute = query => http.post(urlcat(`/routes/create`, query));

export const updateRoute = ({ routeId, ...payload }) =>
  http.patch(`/routes/${routeId}`, payload);

export const updateLocationOrder = ({ routeId, ...payload }) =>
  http.post(`/routes/${routeId}/update-order`, payload);

export const updateOptimizedRouteLocations = payload =>
  http.patch(`/routes-location-optimize`, payload);
