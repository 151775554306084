import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  Spinner,
  Input,
} from 'reactstrap';
import MultiSelectDropDown from 'components/MultiSelect';
import Typography from 'components/Typography';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { MdMyLocation } from 'react-icons/md';
import RangeSlider from 'react-bootstrap-range-slider';
import TagsInput from 'react-tagsinput';
import { getLocationTypes } from 'utils/constants';
import { fetchChains } from '../../store/requests/chainRequest';
import { useInfiniteLocationTypes } from '../../apis/queries/locationTypes.queries';
import Select from '../../components/shared/FormInputs/Select';
const TemplateFilterModal = ({
  show,
  onClose,
  changeValue = null,
  value = {},
  onSave = null,
  showSpinner = false,
  search,
}) => {
  const [locationTypes, setLocationTypes] = useState([]);
  const [updatedLocationTypes, setUpdatedLocationTypes] = useState([]);

  const [additionalInputs, setAdditionalInputs] = useState([]);
  const [locationData, setLocationData] = useState({
    startLocation: {},
    endLocation: {},
  });
  const [chains, setChains] = useState([]);

  const locationTypesQuery = useInfiniteLocationTypes({
    limit: 10,
    page: 1,
  });

  useEffect(() => {
    getChains();
  }, []);
  const getChains = async () => {
    let chainResp = await fetchChains();
    if (chainResp && chainResp.data && chainResp.name !== 'Error') {
      let chain = chainResp.data.data.map(items => ({
        label: items.name,
        id: items._id,
      }));
      setChains(chain);
    }
  };

  const changePlace = async (placeData, type) => {
    const results = await geocodeByAddress(placeData.description);
    const latLng = await getLatLng(results[0]);
    const data = { ...locationData };
    data[type] = latLng;
    if (type === 'startLocation') {
      changeValue('latlng', latLng);
    } else if (Object.keys(data.startLocation).length === 0) {
      changeValue('latlng', latLng);
    }
    setLocationData(data);
  };
  const changeAdditionalInputs = key => {
    let data = [...additionalInputs];
    if (additionalInputs.indexOf(key) > -1) {
      data = data.filter(items => items !== key);
    } else {
      data.push(key);
    }
    setAdditionalInputs(data);
  };
  const onRequestClose = () => {
    setAdditionalInputs([]);
    onClose();
  };

  const getLocationType = async () => {
    const locationTypeResp = await getLocationTypes();
    setLocationTypes(locationTypeResp);
  };

  const formatLocationTypesList = () =>
    (locationTypesQuery.data &&
      locationTypesQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )
        .map(({ _id, name }) => ({ value: _id, label: name }))) ||
    [];

  const loadMoreLocationTypes = event => {
    locationTypesQuery.hasNextPage && locationTypesQuery.fetchNextPage();
    event.stopPropagation();
  };

  useEffect(() => {
    setUpdatedLocationTypes(formatLocationTypesList());
  }, [locationTypesQuery.data]);

  return (
    <Modal isOpen={show} toggle={onRequestClose} className="template-modal">
      <ModalHeader toggle={onRequestClose}>{'Filter'}</ModalHeader>
      <ModalBody className="template-modal-body">
        <Row className="m-0 p-0">
          <Col xs={6}>
            <Typography type="h5" className="mb-0">
              Filter Name
            </Typography>
            <Typography className="text-muted mb-1">
              Enter your filter name
            </Typography>
            <Input
              value={value.name}
              onChange={e => changeValue('name', e.target.value)}
            />
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Typography type="h5" className="mb-0">
                Stop type
              </Typography>
              <Typography className="text-muted mb-1">
                Display only the selected type of spots
              </Typography>
              {/* <MultiSelectDropDown
                multi
                options={locationTypes}
                placeholder="Select Type"
                labelField="name"
                valueField="name"
                onSelect={data =>
                  changeValue(
                    'params.type',
                    data.map(items => items.name),
                  )
                }
                value={locationTypes.filter(items => items.name === value.type)}
              /> */}
              <Select
                options={updatedLocationTypes}
                loadMore={loadMoreLocationTypes}
                placeholder="Select"
                onChange={val => {
                  changeValue('type', val && val.value);
                  // setSelectedOfficeItem(val)
                }}
                isLoading={locationTypesQuery.isFetching}
                hasNextPage={locationTypesQuery.hasNextPage}
                // value={selectedOfficeItem}
                // onInputChange={handleSearchableOfficeInput}
                isClearable
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row className="m-0 p-0">
          <Col xs={6}>
            <FormGroup>
              <Typography type="h5" className="mb-0">
                Starting Location
              </Typography>
              <Typography className="text-muted mb-1">
                Display stops near the starting location
              </Typography>
              <div>
                <GooglePlacesAutocomplete
                  inputClassName="form-control"
                  placeholder="My Current Location or Search Location"
                  onSelect={placeData =>
                    changePlace(placeData, 'startLocation')
                  }
                  autocompletionRequest={{
                    fields: [
                      'address_component',
                      'name',
                      'geometry',
                      'formatted_address',
                    ],
                    // types: ['establishment'],
                  }}
                />
                <div className="location-icon-filter">
                  <MdMyLocation />
                </div>
              </div>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Typography type="h5" className="mb-0">
                End Location
              </Typography>
              <Typography className="text-muted mb-1">
                Display stops near the end location
              </Typography>
              <div>
                <GooglePlacesAutocomplete
                  inputClassName="form-control"
                  placeholder="My Current Location or Search Location"
                  onSelect={placeData => changePlace(placeData, 'endLocation')}
                  autocompletionRequest={{
                    fields: [
                      'address_component',
                      'name',
                      'geometry',
                      'formatted_address',
                    ],
                    types: ['establishment'],
                  }}
                />
                <div className="location-icon-filter">
                  <MdMyLocation />
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row className="m-0 p-0">
          <Col xs={6}>
            <FormGroup>
              <Typography type="h5" className="mb-0">
                Max Number Of Stops
              </Typography>
              <Typography className="text-muted mb-1">
                Display selected number of stops
              </Typography>
              <Input
                placeholder={1}
                type="number"
                min={1}
                value={value.limit}
                onChange={e => changeValue('limit', parseInt(e.target.value))}
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Typography type="h5" className="mb-0">
                Last Visited Days
              </Typography>
              <Typography className="text-muted mb-1">
                Display stops with in the visiting days
              </Typography>
              <MultiSelectDropDown
                options={[
                  { id: 0, label: '0 Day' },
                  { id: 1, label: '1 Day' },
                  { id: 3, label: '3 Days' },
                  { id: 7, label: '7 Days' },
                  { id: 15, label: '15 Days' },
                ]}
                placeholder="Select Type"
                labelField="label"
                valueField="id"
                onSelect={data =>
                  changeValue(
                    'params.lastVisit',
                    data.map(items => items.id),
                  )
                }
                value={[
                  { id: 0, label: '0 Day' },
                  { id: 1, label: '1 Day' },
                  { id: 3, label: '3 Days' },
                  { id: 7, label: '7 Days' },
                  { id: 15, label: '15 Days' },
                ].filter(items => items.id === value.type)}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row className="m-0 p-0">
          <Col xs={12}>
            <FormGroup>
              <Typography type="h5" className="mb-0">
                Distance (km)
              </Typography>
              <Typography className="text-muted mb-1">
                Display Stops within the filtered radius
              </Typography>
              <Row className="mb-0">
                <Col>
                  <Typography className="justify-content-end text-muted mb-1">
                    1
                  </Typography>
                </Col>
                <Col className="text-right text-muted mb-1">
                  <Typography>250</Typography>
                </Col>
              </Row>
              <RangeSlider
                value={value.kilometers || 1}
                onChange={changeEvent =>
                  changeValue('kilometers', changeEvent.target.value)
                }
                min={1}
                max={250}
                size="lg"
                tooltip="on"
                tooltipPlacement="top"
                inputProps={{
                  style: {
                    width: '100%',
                  },
                }}
                tooltipStyle={{
                  position: 'absolute',
                  color: 'white',
                }}
                tooltipLabel={value => (
                  <div>
                    <div className="triangle-up"></div>
                    <div className="tooltip-body">{value || 1}</div>
                  </div>
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row className="m-0 p-0 ">
          <FormGroup check className="pr-4 fill-check">
            <Label className="form-check-label">
              <Input
                type="checkbox"
                onChange={() => changeAdditionalInputs('zip')}
              />
              <span>Zip</span>
            </Label>
          </FormGroup>
          <FormGroup check className="pr-4 fill-check">
            <Label className="form-check-label">
              <Input
                type="checkbox"
                onChange={() => changeAdditionalInputs('city')}
              />
              <span>City</span>
            </Label>
          </FormGroup>
          <FormGroup check className="pr-4 fill-check">
            <Label className="form-check-label">
              <Input
                // className="ml-1"
                type="checkbox"
                onChange={() => changeAdditionalInputs('chain')}
              />
              <span>Chain</span>
            </Label>
          </FormGroup>
          <FormGroup check className="pr-4 fill-check">
            <Label className="form-check-label">
              <Input
                type="checkbox"
                onChange={() => changeAdditionalInputs('dbaName')}
              />
              <span>DBA</span>
            </Label>
          </FormGroup>
          {/* <FormGroup check className="pr-4 fill-check">
            <Label className="form-check-label">
              <Input
                type="checkbox"
                onChange={() => changeAdditionalInputs('legal')}
              />
              <span>legal</span>
            </Label>
          </FormGroup> */}
        </Row>
        {additionalInputs && additionalInputs.length > 0 && <hr />}
        <Row className="m-0 p-0">
          {additionalInputs.map(items => {
            if (items === 'chain') {
              return (
                <Col xs={6} className="mb-3" key={items}>
                  <MultiSelectDropDown
                    multi
                    options={chains}
                    placeholder="Select chain"
                    labelField="label"
                    valueField="id"
                    onSelect={data =>
                      changeValue(
                        'params.chain',
                        data.map(items => items.id),
                      )
                    }
                    value={locationTypes.filter(
                      items => items.id === value.type,
                    )}
                  />
                </Col>
              );
            } else
              return (
                <Col xs={6} className="mb-3" key={items}>
                  <TagsInput
                    value={value.params[items] || []}
                    onChange={data => changeValue(`params.${items}`, data)}
                    addKeys={[13, 188]}
                    addOnBlur
                    onlyUnique
                    inputProps={{
                      placeholder: `Add a ${items.toUpperCase()}`,
                      className: 'p-2 border-none',
                    }}
                  />
                </Col>
              );
          })}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            search();
            onRequestClose();
          }}
          disabled={showSpinner}
        >
          {showSpinner ? <Spinner size={'sm'} color="primary" /> : 'Search'}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onSave();
            onRequestClose();
          }}
          disabled={showSpinner}
        >
          {showSpinner ? <Spinner size={'sm'} /> : 'Save As New'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default TemplateFilterModal;
