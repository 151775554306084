import AuthForm from 'components/AuthForm';
import React, { useState } from 'react';
import { Card, Col, Row, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { userLoginAction } from '../store/actions/authAction';
import { TOAST } from '../store/actions';
import logo200Image from 'assets/img/logo/ExpiTrack.png';

// class AuthPage extends React.Component {
const AuthPage = ({ authState }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const initial_loading = useSelector(state => state.auth.initial_load);

  const handleLogoClick = () => {
    history.push('/dashboard');
  };

  const validateForm = () => {
    if (!validator.isEmail(email)) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: 'Invalid email!',
          show: true,
        },
      });

      return false;
    } else if (!validator.isLength(password, { min: 6, max: 20 })) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: 'Invalid Password!',
          show: true,
        },
      });
      return false;
    } else {
      return true;
    }
  };

  const login = () => {
    let isValid = validateForm();
    if (isValid) {
      dispatch(userLoginAction({ email, password }, history));
    } else {
      return;
    }
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#28376E',
      }}
    >
      <Col md={6} lg={4}>
        {initial_loading ? (
          <Spinner
            size="lg"
            animation="border"
            className="page-spinner"
            variant="info"
          />
        ) : (
          <div>
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 150, height: 30, cursor: 'pointer' }}
                alt="logo"
                // onClick={onLogoClick}
              />
            </div>
            <Card
              body
              style={{
                borderRadius: 10,
                paddingVertical: 30,
                paddingLeft: 50,
                paddingRight: 50,
              }}
            >
              <AuthForm
                authState={authState}
                // onChangeAuthState={handleAuthState}
                showLogo={false}
                onSubmit={login}
                onLogoClick={handleLogoClick}
                usernameInputProps={{
                  value: email,
                  type: 'email',
                  placeholder: 'example@gmail.com',
                  onKeyPress: e => {
                    if (e.which === 13) return login();
                  },
                  onChange: e => {
                    setEmail(e.target.value);
                  },
                }}
                passwordInputProps={{
                  value: password,
                  onKeyPress: e => {
                    if (e.which === 13) return login();
                  },

                  type: 'password',
                  placeholder: '*********',

                  onChange: e => {
                    setPassword(e.target.value);
                  },
                }}
              />
            </Card>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default AuthPage;
