import httpServices from '../../common/service';
const url = process.env.REACT_APP_BASE_URL;

export const fetchChains = async () => {
  try {
    let uri = `${url}/chains`;
    let chainResp = await httpServices.httpGetCall(uri);
    return chainResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};

export const addChain = async data => {
  try {
    let uri = `${url}/chains`;
    let chainResp = await httpServices.httpPostCall(uri, data);
    return chainResp;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
