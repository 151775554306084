import http from '../http';
import urlcat from 'urlcat';

export const fetchActiveAgents = query =>
  http.get(urlcat('/users/active-agents', query));

export const fetchTopOffices = query =>
  http.get(urlcat('/users/top-office', query));

export const fetchTopAgents = query =>
  http.get(urlcat('/users/top-agents', query));

export const fetchUsers = query => http.get(urlcat('/users', query));

export const fetchUserById = (userId, query) =>
  http.get(urlcat(`/users/${userId}`, query));

export const fetchVisitedLocations = (id, query) =>
  http.get(urlcat(`/users/route-location-details/${id}`, query));

export const fetchReports = query =>
  http.get(urlcat('/users/visit-report', query));

export const fetchReportStats = query =>
  http.get(urlcat('/users/visit-report-stats', query));

export const fetchRouteTemplatesByUserId = (userId, query) =>
  http.get(urlcat(`/users/${userId}/route-templates`, query));

export const fetchConfirmedVisitedLocations = query =>
  http.get(urlcat('/users/confirmed-visits', query));

export const fetchUnConfirmedVisitedLocations = query =>
  http.get(urlcat('/users/unconfirmed-visits', query));

export const fetchVisitNotes = query =>
  http.get(urlcat('/users/visit-notes', query));
