import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
} from 'reactstrap';
import MultiSelectDropDown from 'components/MultiSelect';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { MdMyLocation } from 'react-icons/md';
import {
  addRouteLocation,
  fetchPaginatedRouteLocations,
  useInfinitePaginatedRouteLocations,
} from '../../store/requests/routeRequest';
import { useSelector, useDispatch } from 'react-redux';
import { TOAST } from 'store/actions';
import { getOptimizedRoutes } from '../../store/requests/directionRequest';
// import { getLocationType } from '../../store/requests/locationTypeRequest';
import Select from '../../components/shared/FormInputs/Select';
import { useInfiniteLocationTypes } from '../../apis/queries/locationTypes.queries';

const AddStopModal = ({
  show,
  onClose,
  value = {},
  route,
  getDetails,
  showSuccessMessage,
  routeId,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.userData);
  const [updatedLocationTypes, setUpdatedLocationTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [formData, setFormData] = useState({
    order: 0,
    name: '',
    location: {},
  });

  const locationTypesQuery = useInfiniteLocationTypes({
    limit: 10,
    page: 1,
  });

  const locationsQuery = useInfinitePaginatedRouteLocations({
    limit: 10,
    page: 1,
    routeId,
  });

  const formatLocationTypesList = () =>
    (locationTypesQuery.data &&
      locationTypesQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )
        .map(({ _id, name }) => ({ value: _id, label: name }))) ||
    [];

  const formatRouteLocationsList = () =>
    (locationsQuery.data &&
      locationsQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )
        .map(({ order, locations }) => ({
          value: order,
          label: locations.dbaName ? locations.dbaName : locations.description,
        }))) ||
    [];

  const loadMoreLocationTypes = event => {
    locationTypesQuery.hasNextPage && locationTypesQuery.fetchNextPage();
    event.stopPropagation();
  };

  const loadMoreRouteLocations = event => {
    locationsQuery.hasNextPage && locationsQuery.fetchNextPage();
    event.stopPropagation();
  };

  const changeValue = (key, value) => {
    let data = { ...formData };
    data[key] = value;
    setFormData(data);
  };
  // useEffect(() => {
  //   if (userData.role) {
  //     window.scrollTo(0, 0);
  //     getLocationTypes();
  //   }
  // }, [userData]);
  // const getLocationTypes = async () => {
  //   const resp = await getLocationType();
  //   if (resp && resp.data && resp.data.data && isArray(resp.data.data))
  //     setLocationTypes(
  //       resp.data.data.map(item => {
  //         return {
  //           label: item.name,
  //           value: item._id,
  //         };
  //       }),
  //     );
  // };

  const changePlace = async placeData => {
    let location = {};
    location.address = placeData.description;
    const results = await geocodeByAddress(placeData.description);
    const latLng = await getLatLng(results[0]);

    location.location = {
      type: 'Point',
      coordinates: [latLng.lng, latLng.lat],
    };
    for (let address_component of results[0].address_components) {
      if (address_component.types[0] === 'locality') {
        location.city = address_component.long_name;
      }

      if (address_component.types[0] === 'country') {
      }

      if (address_component.types[0] === 'postal_code') {
        location.zip = address_component.long_name;
      }
    }

    setFormData({ ...formData, location });
  };
  const validateForm = () => {
    if (!formData.name || formData.name.trim().length === 0) {
      showMessage('error', 'Error', "Name isn't valid");
      return false;
    } else if (!formData.location || !formData.location.location) {
      showMessage('error', 'Error', 'Please select a location');
      return false;
    } else if (!formData.type || !formData.type.length) {
      showMessage('error', 'Error', 'Please select a location type');
      return false;
    } else {
      return true;
    }
  };
  const showMessage = (type, title, message) => {
    dispatch({
      type: TOAST.SHOW,
      load: {
        type: type,
        title: title,
        message: message,
        show: true,
      },
    });
  };

  async function addStop() {
    let validate = validateForm();
    if (!validate) return;
    const body = {
      type: formData.type.toLowerCase(),
      description: formData.name,
      address: formData.location.address,
      city: formData.location.city,
      zip: formData.location.zip,
      location: formData.location.location,
      status: 'active',
      userId: userData.id,
    };
    let points =
      locationsQuery.data &&
      locationsQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )
        .map(({ locations }) => locations.location.coordinates);

    points.push(body.location.coordinates);

    const resp = await getOptimizedRoutes(points);
    if (resp && !resp.paths) {
      showMessage('error', 'Error', resp.message);
      return;
    }
    addRouteLocation(route.id, formData.order, body).then(function (response) {
      showSuccessMessage('Stop added!');
      getDetails();
      onClose();
    });
  }

  useEffect(() => {
    setUpdatedLocationTypes(formatLocationTypesList());
  }, [locationTypesQuery.data]);

  useEffect(() => {
    setLocations(formatRouteLocationsList());
  }, [locationsQuery.data]);

  return (
    <Modal isOpen={show} toggle={onClose} className="add-stop">
      <ModalHeader toggle={onClose}>{'Add Stop'}</ModalHeader>
      <ModalBody>
        <Row className="m-0">
          <Col xs={6}>
            <FormGroup>
              <Label>After location</Label>
              {/* <MultiSelectDropDown
                options={locations}
                placeholder="Select"
                labelField="description"
                valueField="id"
                onSelect={data => {
                  changeValue('order', data[0].order);
                }}
                value={
                  locations.length > 0
                    ? locations.filter(items => items.id === value.locationId)
                    : []
                }
                searchable={false}
              /> */}
              <Select
                options={locations}
                loadMore={loadMoreRouteLocations}
                placeholder="Select"
                onChange={val => {
                  changeValue('order', val && val.value);
                }}
                isLoading={locationsQuery.isFetching}
                hasNextPage={locationsQuery.hasNextPage}
                // value={selectedOfficeItem}
                // onInputChange={handleSearchableOfficeInput}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>
                Location type <span className="required">*</span>
              </Label>
              {/* <MultiSelectDropDown
                options={locationTypes || []}
                placeholder="Select"
                labelField="name"
                valueField="id"
                onSelect={data => {
                  if (data && data.length > 0) {
                    changeValue('type', data[0].name);
                  } else {
                    changeValue('type', '');
                  }
                }}
                value={
                  locations.length > 0
                    ? locations.filter(items => items.id === value.locationId)
                    : []
                }
                searchable={false}
              /> */}
              <Select
                options={updatedLocationTypes}
                loadMore={loadMoreLocationTypes}
                placeholder="Select"
                onChange={val => {
                  changeValue('type', val && val.value);
                  // setSelectedOfficeItem(val)
                }}
                isLoading={locationTypesQuery.isFetching}
                hasNextPage={locationTypesQuery.hasNextPage}
                // value={selectedOfficeItem}
                // onInputChange={handleSearchableOfficeInput}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label>
                Stop name <span className="required">*</span>
              </Label>
              <Input
                placeholder="Enter name"
                onChange={e => changeValue('name', e.target.value)}
                value={value.name}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label>
                Location / Address <span className="required">*</span>
              </Label>
              <div className="relative-sec">
                <GooglePlacesAutocomplete
                  inputClassName="form-control"
                  placeholder="Search location / address"
                  onSelect={placeData => changePlace(placeData)}
                  autocompletionRequest={{
                    fields: [
                      'address_component',
                      'name',
                      'geometry',
                      'formatted_address',
                    ],
                    types: ['establishment'],
                  }}
                />
                <div className="location-icon-filter">
                  <MdMyLocation />
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => addStop()}>
          Add
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddStopModal;
