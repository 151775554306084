import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import MultiSelectDropDown from 'components/MultiSelect';

import { existingLocationAfter } from '../../store/requests/routeRequest';
import { fetchUnassignedOfficeLocation } from '../../store/requests/locationRequest';
import { fetchOffices } from '../../store/requests/officeRequests';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TOAST } from 'store/actions';
import { useDispatch } from 'react-redux';
import { getOptimizedRoutes } from '../../store/requests/directionRequest';

const AddRouteLocation = ({
  show,
  onClose,
  locations = [],
  value = {},
  route,
  getDetails,
  showSuccessMessage,
  order,
}) => {
  const history = useHistory();
  const userData = useSelector(state => state.auth.userData);
  let branchOffices = useSelector(state => state.auth.branch_offices);

  useEffect(() => {
    if (userData.role) {
      getOffices();
    }
  }, [userData]);
  useEffect(() => {
    if (userData.role === 'office-manager') {
      setOffices(branchOffices);
    }
  }, [branchOffices]);
  const [formData, setFormData] = useState({
    order: '',
    locationId: '',
  });
  const dispatch = useDispatch();

  const [allLocations, setAllLocations] = useState([]);
  const [offices, setOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState();
  const [selectOffice, setSelectOffice] = useState(true);
  useEffect(() => {
    setFormData({ ...formData, order });
  }, [order]);
  useEffect(() => {
    if (route && route.officeId) {
      setSelectedOffice(route.officeId);
      setSelectOffice(false);
      getLocations(route.officeId);
    }
  }, [route]);

  const getOffices = async () => {
    if (userData.role === 'admin') {
      // let officeResp = await fetchOffices();
      // if (officeResp && officeResp.name !== 'Error' && officeResp.data) {
      //   officeResp.data = officeResp.data.map(items => ({
      //     ...items,
      //     id: items._id,
      //   }));
      //   setOffices(officeResp.data);
      setOffices([]);
      // }
    }
  };

  const getLocations = async officeId => {
    let resp;

    resp = await fetchUnassignedOfficeLocation(officeId);
    if (resp && resp.name !== 'Error') {
      resp.data = resp.data.map(items => items.location[0]);
      setAllLocations(resp.data);
    }
  };

  const changeValue = (key, value) => {
    let data = { ...formData };
    data[key] = value;
    setFormData(data);
  };

  const changeOffice = id => {
    setAllLocations([]);
    setSelectedOffice(id);
    getLocations(id);
  };

  async function addStop() {
    if (!formData.locationId || formData.locationId.length === 0) {
      return dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: 'Please select a location first.',
          show: true,
        },
      });
    }
    let points = locations.map(items => items.location.coordinates);
    let unassignedLocation = allLocations.find(
      item => item.id === formData.locationId,
    );
    if (unassignedLocation) {
      points.push(unassignedLocation.location.coordinates);
    }

    let resp = await getOptimizedRoutes(points);
    if (resp && !resp.paths) {
      return dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: resp.message,
          show: true,
        },
      });
    }
    existingLocationAfter(formData.locationId, route.id, formData.order).then(
      function (response) {
        showSuccessMessage('Location added!');
        getDetails(true);
        onClose();
      },
    );
  }
  return (
    <Modal isOpen={show} toggle={onClose} className="add-stop">
      <ModalHeader toggle={onClose}>{'Add location after'}</ModalHeader>
      <ModalBody>
        <Row className="m-0">
          <Col xs={12}>
            <FormGroup>
              <Label>Select office</Label>
              <MultiSelectDropDown
                disabled={!selectOffice}
                options={offices}
                placeholder="Select"
                labelField="name"
                valueField="id"
                onSelect={data => {
                  data && data[0] && changeOffice(data[0].id);
                }}
                value={
                  offices.length > 0
                    ? offices.filter((items, index) => {
                        return items.id === selectedOffice;
                      })
                    : []
                }
                searchable={false}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label>After location</Label>
              <MultiSelectDropDown
                options={locations}
                placeholder="Select"
                labelField="description"
                valueField="id"
                onSelect={data => {
                  changeValue('order', data && data[0] && data[0].order);
                }}
                value={
                  locations.length > 0
                    ? locations.filter((items, index) => {
                        return items.order === formData.order;
                      })
                    : []
                }
                searchable={false}
              />
            </FormGroup>
          </Col>

          <Col xs={12}>
            <FormGroup>
              <Label>
                Location <span className="required">*</span>
              </Label>
              <div className="relative-sec">
                <MultiSelectDropDown
                  options={allLocations}
                  placeholder="Select"
                  labelField="description"
                  valueField="id"
                  onSelect={data => changeValue('locationId', data[0].id)}
                  value={
                    allLocations && allLocations.length > 0
                      ? allLocations.filter(
                          items => items.id === value.locationId,
                        )
                      : []
                  }
                  searchable={false}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          size="sm"
          onClick={() =>
            history.push(
              `/add-edit-location/${route.id}/route/${formData.order}`,
            )
          }
        >
          Add new location
        </Button>
        <Button color="primary" onClick={() => addStop()}>
          Add
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddRouteLocation;
