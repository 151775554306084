import { useHistory, useLocation } from 'react-router-dom';
import { getActiveQuery } from '../utils';

// const LIMIT = 'limit';

const useLimit = (limitKey = 'limit') => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const limit = getActiveQuery(location, limitKey);

  const handleLimit = limit => {
    searchParams.set(limitKey, limit);
    const newQueryString = searchParams.toString();
    history.replace({
      search: newQueryString,
    });
  };

  return {
    handleLimit,
    limit: limit || 10,
  };
};

export default useLimit;
